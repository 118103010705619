import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./services.css";
import ImageNotFound from "../../assets/images/not-found.png";

const Services = () => {
  const [info_service, setInfoService] = useState([]);
  const [info_services, setInfoServices] = useState([]);
  const URLPUBLIC = process.env.REACT_APP_PUBLIC;
  const URLZFILES = process.env.REACT_APP_API_ZFILES;

  useEffect(() => {
    fetch(URLPUBLIC + 'data/pagehome/service.json').then(response => response.json()).then(data => setInfoService(data[0]));
    fetch(URLPUBLIC + 'data/pageservices/services.json').then(response => response.json()).then(data => setInfoServices(data));
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    autoplay:true,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          prevArrow:false,
          nextArrow:false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow:false,
          nextArrow:false,
        },
      },
    ],
  };

  return (
    <>
      <section className="feature-section">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading text-center">
                <h1 className="text-uppercase">{info_service.service_title}</h1>
                <h6 className="h6 text-uppercase mt-3">{info_service.service_sub_title}</h6>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Slider {...settings}>
                {info_services.map((item, inx) => {
                  return (
                    <Card key={inx}>
                      <Card.Img
                        loading="lazy" 
                        variant="top"
                        src={item.service_icon ? URLZFILES + item.service_icon : ImageNotFound}
                        className="img-fluid"
                        alt={item.service_title}
                      />
                      <Card.Title>{item.service_title}</Card.Title>
                      <Card.Text>
                        <div dangerouslySetInnerHTML={{ __html: item.service_sub_title }} />
                      </Card.Text>
                    </Card>
                  );
                })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Services;
