import React from "react";
import { Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../Cards/card.css";
import ImageNotFound from "../../assets/images/not-found.png";

const Cards = ({destination}) => {
  const URLZFILES = process.env.REACT_APP_API_ZFILES;

  return (
    <>
      <div className="img-box">
      <NavLink className="body-text text-dark text-decoration-none"> 
        <Card>
          <Card.Img
            loading="lazy" 
            style={{height:"400px"}}
            variant="top"
            src={destination.destination_image ? URLZFILES + destination.destination_image : ImageNotFound}
            className="img-fluid"
            alt={destination.destination_title}
          />
          <Card.Title>
          {destination.destination_title}
          </Card.Title>
          <span className="tours">{destination.destination_price}</span>
        </Card>
        </NavLink>
      </div>
    </>
  );
};

export default Cards;
