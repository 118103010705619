import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Card, Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import "../About/about.css";
import ImageNotFound from "../../assets/images/not-found.png";

const About = () => {
    const [info_about, setInfoAbout] = useState([]);
    const [info_counts, setCounts] = useState([]);
    const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
    const URLZFILES = process.env.REACT_APP_API_ZFILES;
    const URLPUBLIC = process.env.REACT_APP_PUBLIC;

    useEffect(() => {
        window.scrollTo(0, 0);
        getPageUpdate();
        fetch(URLPUBLIC + 'data/pageabout/abouts.json').then(response => response.json()).then(data => setInfoAbout(data));
        fetch(URLPUBLIC + 'data/pagehome/counts.json').then(response => response.json()).then(data => setCounts(data));
    }, []);

    const getPageUpdate = async () => {
        await axios.get(ENDPOINT + 'get-home-page');
    };

    return (
        <>
            <Breadcrumbs title="Nosotros" pagename="Nosotros" />
            <section className="py-5">
                <Container>
                    <Row>
                        <Col md="8">
                            <div className="about-content">
                                <div className="about-image position-relative">
                                    <img loading="lazy" src={info_about.about_image ? URLZFILES + info_about.about_image: ImageNotFound} alt="Nosotros" className="img-fluid rounded-5" />
                                    <div className="about-image-content position-absolute top-50 end-0 p-md-4 p-3 rounded-5 shadow-sm">
                                        <h3 className="h2 fw-bold text-white text-uppercase text-center">{info_about.about_sub_title}</h3>
                                    </div>
                                </div>
                            </div>
                            <h2 className="h2 font-bold pt-4 pb-2">
                                {info_about.about_title}
                            </h2>
                            <div dangerouslySetInnerHTML={{ __html: info_about.about_description }} />
                        </Col>
                        <Col md="4">
                            <Card className="border shadow rounded-3 mb-4">
                                <Card.Body className="text-center">
                                    <div className="d-flex justify-content-center align-item-search my-2">
                                        <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                                            <img loading="lazy" height={64} width={64} src={info_about.about_indicator_icon_trip ? URLZFILES + info_about.about_indicator_icon_trip : ImageNotFound} alt="icon" className="img-fluid" />
                                        </div>
                                    </div>
                                    <Card.Title className="fw-bold h5">+{info_counts.tickets}</Card.Title>
                                    <p className="mb-2 body-text">
                                        {info_about.about_indicator_text_trip}
                                    </p>
                                </Card.Body>
                            </Card>

                            <Card className="border shadow rounded-3 mb-4">
                                <Card.Body className="text-center">
                                    <div className="d-flex justify-content-center align-item-search my-2">
                                        <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                                            <img loading="lazy" height={64} width={64} src={info_about.about_indicator_icon_package ? URLZFILES + info_about.about_indicator_icon_package : ImageNotFound} alt="icon" className="img-fluid" />
                                        </div>
                                    </div>
                                    <Card.Title className="fw-bold h5">+{info_counts.packages}</Card.Title>
                                    <p className="mb-2 body-text">
                                        {info_about.about_indicator_text_package}
                                    </p>
                                </Card.Body>
                            </Card>

                            <Card className="border shadow rounded-3 mb-4">
                                <Card.Body className="text-center">
                                    <div className="d-flex justify-content-center align-item-search my-2">
                                        <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                                            <img loading="lazy" height={64} width={64} src={info_about.about_indicator_icon_unit ? URLZFILES + info_about.about_indicator_icon_unit : ImageNotFound} alt="icon" className="img-fluid" />
                                        </div>
                                    </div>
                                    <Card.Title className="fw-bold h5">+{info_counts.packages}</Card.Title>
                                    <p className="mb-2 body-text">
                                        {info_about.about_indicator_text_unit}
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default About;
