import React, { useState, useEffect } from "react";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading/Loading';
import "../Booking/booking.css";

const Booking = () => {
    const [card, setCard] = useState({
        name: '',
        last_name: '',
        email: '',
        phone_number: '',
        amount: 0,
        description: 'Venta de Boleta'
    });
    const [loading, setLoading] = useState(false);
    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [purchase_order, setPurchaseOrder] = useState('');
    const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
    const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

    useEffect(() => {
        window.scrollTo(0, 0);
        let dataResume = JSON.parse(localStorage.getItem('dataResume'));
        setOrigin(dataResume.origin);
        setDestination(dataResume.destination);
        setData(dataResume.dataForm);
        setTotal(dataResume.total)
        setPurchaseOrder(dataResume.purchase_order);
        setCard({ ...card, amount: dataResume.total });
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCard({ ...card, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = new FormData();
        form.append('amount', total);
        form.append('name', data[0].name);
        form.append('last_name', data[0].last_name);
        form.append('email', data[0].email);
        form.append('phone_number', data[0].phone_number);
        form.append('description', 'Venta de Boleta');
        form.append('purchase_order', purchase_order);
        setLoading(true);
        await axios.post(ENDPOINT + 'payment', form).then(response => {
            localStorage.removeItem('dataResume');
            localStorage.removeItem('dataTrip');
            window.location.href = response.data.data;
        }).catch(function (error) {
            setLoading(false);
            Swal.fire({
                icon: 'error',
                title: '<div className="title-custom-alert">' + TITLEALERT + '</div>',
                showConfirmButton: true,
                html: '<div className="text-custom-alert">' + error.response.data.msg + '</div>',
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' }
            });
        });
    };

    return (
        <>
            {loading && <Loading />}
            <Breadcrumbs title="Realizar pago" pagename="Realizar pago" />
            <section className="booking-section py-5">
                <Container>
                    <Row>
                        {/*<Col md="8" lg="8">
                            <div className="booking-form-wrap border rounded-3">
                                <div className="form-title px-4 border-bottom py-3">
                                    <h3 className="h4 font-bold m-0">Introduce tus datos</h3>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row p-4">
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group mt-3 mb-3">
                                                <input
                                                    required
                                                    className={`form-control form-control-sm`}
                                                    type="text"
                                                    name="name"
                                                    placeholder="Ingrese nombres"
                                                    value={card.name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group mt-3 mb-3">
                                                <input
                                                    required
                                                    className={`form-control form-control-sm`}
                                                    type="text"
                                                    name="last_name"
                                                    placeholder="Ingrese apellidos"
                                                    value={card.last_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group mt-3 mb-3">
                                                <input
                                                    required
                                                    className={`form-control form-control-sm`}
                                                    type="email"
                                                    name="email"
                                                    placeholder="Ingrese email"
                                                    value={card.email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group mt-3 mb-3">
                                                <input
                                                    required
                                                    className={`form-control form-control-sm`}
                                                    type="text"
                                                    name="phone_number"
                                                    placeholder="Ingrese teléfono"
                                                    value={card.phone_number}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="primaryBtn m-4">Realizar pago</button>
                                </form>
                            </div>
                        </Col>*/}

                        <Col md="8" lg="8">
                            <Card className="card-info p-0 shadow-sm bg-white">
                                <Card.Header>
                                    <h1 className="font-bold h4 mt-2">Resumen pasajeros</h1>
                                </Card.Header>
                                <Card.Body className="pb-0">
                                    {data.map((item, index) => (
                                        <>
                                            <div key={index} className="p-2 shadow-sm mb-2">
                                                <h1 className="font-bold h5 mt-2 border-bottom">Datos Pasajero # {index+1}</h1>   
                                                <p className="font-bold h6 mt-2">Nombres: {item.name}</p>
                                                <p className="font-bold h6 mt-2">Apellidos: {item.last_name}</p>
                                                <p className="font-bold h6 mt-2">Email: {item.email}</p>
                                                <p className="font-bold h6 mt-2">Teléfono: {item.phone}</p>
                                            </div> 
                                        </>
                                    ))}
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-between mt-2 py-4">
                                    <form onSubmit={handleSubmit}>
                                        <button className="primaryBtn">Realizar pago</button>
                                    </form>
                                </Card.Footer>
                            </Card>
                        </Col>

                        <Col md="4" lg="4">
                            <Card className="card-info p-0 shadow-sm bg-white">
                                <Card.Header>
                                    <h1 className="font-bold h4 mt-2">Resumen de precios</h1>
                                </Card.Header>
                                <Card.Body className="pb-0">
                                    <ListGroup>
                                        <ListGroup.Item className="border-0 d-flex justify-content-between h6 pt-0">
                                            <strong> Origen </strong> <strong>Destino</strong>
                                        </ListGroup.Item>
                                        <ListGroup.Item className="border-0 d-flex justify-content-between h6 pt-0">
                                            <span> {origin} </span> <span> {destination} </span>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    <ListGroup>
                                        {data.map((item, index) => (
                                            <ListGroup.Item key={index} className="border-0 d-flex justify-content-between h5 pt-0">
                                                <span> Puesto: {item.seat}</span> <strong>${item.price_ticket}</strong>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Card.Body>
                                <Card.Footer className="d-flex justify-content-between py-4">
                                    <span className="font-bold h5">Total a Pagar</span>
                                    <strong className="font-bold h5">${total}</strong>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default Booking