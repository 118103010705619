import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Destinations from "./pages/Destinations/Destinations";
import Services from "./pages/Services/Services";
import Blogs from "./pages/Blogs/Blogs";
import Contact from "./pages/Contact/Contact";
import PhotoGallery from "./pages/PhotoGallery/PhotoGallery";
import DetailBlog from "./pages/Blogs/DetailBlog";
import SearchTrip from "./pages/SearchTrip/SearchTrip";
import Header from "./components/Common/Header/Header";
import Footer from "./components/Common/Footer/Footer";
import Booking from "./pages/Booking/Booking";
import PaymentSuccess from "./pages/Booking/PaymentSuccess";
import BusBooking from "./pages/SearchTrip/BusBooking";
import TravelBookingSystem from "./pages/SearchTrip/TravelBookingSystem";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="nosotros" element={<About />} />
        <Route path="destinos" element={<Destinations />} />
        <Route path="servicios" element={<Services />} />
        <Route path="blog" element={<Blogs />} />
        <Route path="detalle-blog" element={<DetailBlog />} />
        <Route path="galeria" element={<PhotoGallery />} />
        <Route path="contacto" element={<Contact />} />
        <Route path="buscar-viaje" element={<SearchTrip />} />
        <Route path="realizar-pago" element={<Booking />} />
        <Route path="pago-exitoso" element={<PaymentSuccess />} />
        <Route path="reservar-bus" element={<BusBooking />} />
        <Route path="sistema-reserva-viaje" element={<TravelBookingSystem />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
