import React, { useEffect, useState } from "react";
import { Container, Navbar, Offcanvas, Nav, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Logo from '../../../assets/images/logo-viala-blanco.png';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import "../Header/header.css";
import Loading from "../../Loading/Loading";
import Swal from 'sweetalert2';

const Header = () => {
  const [loading, setLoading] = useState(false);
  const [folio, setFolio] = useState("");
  const [open, setOpen] = useState(false);
  const [showprint, setShowPrint] = useState(false);
  const [path, setPath] = useState('');
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;

  const toggleMenu = () => {
    setOpen(!open);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky)
    }
  })

  const handleClosePrint = () => setShowPrint(false);
  const handleShowPrint = () => setShowPrint(true);

  // sticky Header 
  const isSticky = (e) => {
    const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    scrollTop >= 120 ? header.classList.add('is-sticky') :
      header.classList.remove('is-sticky')
  }

  const handlePrint = async (e) => {
    e.preventDefault();
    setLoading(true);
    let user_id = 1;
    const form = new FormData();
    form.append("folio", folio);
    form.append("user_id", user_id);
    await axios.post(ENDPOINT + 'print-pdf-ticket-folio', form, { responseType: "blob" }).then((response) => { 
      const url = window.URL.createObjectURL(response.data);
      window.open(url, '_blank');
      setLoading(false);
      setFolio("");
      handleClosePrint();
    }).catch((error) => {
      setLoading(false);  
      console.log(error);
      Swal.fire({
        title: 'Error!',  
        text: 'No se encontrado registro en la busqueda, verifique el folio',
        icon: 'error',
      });
    });
  };

  const pathname = window.location.pathname;
  console.log(pathname);
  const newpathname = pathname.slice(1);
  console.log(newpathname);


  return (

    <>
      {loading && <Loading />}
      <Modal 
        show={showprint} 
        onHide={handleClosePrint} 
        backdrop="static" 
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="bg-header-modal">
          <Modal.Title>Reimprimir Boleta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="formulario" onSubmit={handlePrint}>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-sm-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className={`form-control`}
                      placeholder="Ingrese el folio de la boleta"
                      id="folio"
                      name="folio"
                      value={folio}
                      onChange={(e) => setFolio(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 mt-3 text-center">
                  <button
                    type="submit"
                    className="primaryBtn w-50">
                    <i className="bi bi-search me-1"></i>
                    Buscar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <header className="header-section">
        <Container>
          <Navbar expand="lg" className="p-0">
            {/* Logo Section  */}
            <Navbar.Brand>
              <NavLink to="/">
                <img
                  src={Logo}
                  width="130"
                  height="80"
                  alt="Logo"
                />
              </NavLink>
            </Navbar.Brand>
            {/* End Logo Section  */}
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="start"
              show={open}
            >
              {/*mobile Logo Section  */}
              <Offcanvas.Header>
                <NavLink to="/">
                  <img
                    src={Logo}
                    width="150"
                    height="90"
                    alt="Logo"
                  />
                </NavLink>
                <span className="navbar-toggler ms-auto" onClick={toggleMenu}>
                  <i className="bi bi-x-lg"></i>
                </span>
              </Offcanvas.Header>
              {/*end mobile Logo Section  */}
              <Offcanvas.Body>
                <Nav className="justify-content-center flex-grow-1 pe-3">
                  <NavLink className="nav-link" to="/" >
                    Inicio
                  </NavLink>

                  <NavLink className="nav-link" to="nosotros" >
                    Nosotros
                  </NavLink>

                  <NavLink className="nav-link" to="destinos" >
                    Destinos
                  </NavLink>

                  <NavLink className="nav-link" to="servicios" >
                    Servicios
                  </NavLink>

                  <NavLink className="nav-link" to="blog" >
                    Blog
                  </NavLink>

                  <NavLink className="nav-link" to="galeria" >
                    Galeria
                  </NavLink>

                  <NavLink className="nav-link" to="contacto" >
                    Contacto
                  </NavLink>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <div className="ms-md-4 ms-2">
              <NavLink
                className="primaryBtn d-none d-sm-inline-block"
                onClick={handleShowPrint}
              >
                <i className="bi bi-printer me-2"></i> Reimprimir Boleta
              </NavLink>
              <li className="d-inline-block d-lg-none ms-3 toggle_btn">
                <i className={open ? "bi bi-x-lg" : "bi bi-list"} onClick={toggleMenu}></i>
              </li>
            </div>
          </Navbar>
        </Container>
      </header>
    </>


  );
};

export default Header;
