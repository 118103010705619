import React, { useEffect, useState } from 'react';
import { Carousel } from "react-bootstrap";
import "../Banner/banner.css"; 
import ImageNotFound from "../../assets/images/not-found.png";

const Banner = () => {
  const [info_banners, setInfoBanners] = useState([]);
  const URLZFILES = process.env.REACT_APP_API_ZFILES;
  const URLPUBLIC = process.env.REACT_APP_PUBLIC;

  useEffect(() => {
    fetch(URLPUBLIC + 'data/pagehome/banners.json').then(response => response.json()).then(data => setInfoBanners(data));
  }, []);

  return (
    <>
      <section className="slider">
        <Carousel variant="dark" slide={false}>
          {info_banners.map((item, index) => {
            return (
              <Carousel.Item>
                <img loading="lazy" src={item.banner_image ? URLZFILES + item.banner_image : ImageNotFound} className="d-block w-100" alt={`slide_${index}`} />
                <Carousel.Caption>
                  <div className="slider_des">
                    <h5 className="heading text-uppercase">
                      {item.banner_title}
                    </h5>
                    <p className="sub_text text-uppercase">
                      <div dangerouslySetInnerHTML={{ __html: item.banner_sub_title }} />
                    </p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </section>
    </>
  );
};

export default Banner;
