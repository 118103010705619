import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import { Container, Row, Col } from 'react-bootstrap';
import PopularCard from '../../components/Cards/PopularCard';
import axios from 'axios';

const Blogs = () => {
    const [info_blogs, setInfoBlogs] = useState([]);
    const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
    const URLPUBLIC = process.env.REACT_APP_PUBLIC;
  
    useEffect(() => {
      window.scrollTo(0, 0);
      getPageUpdate();
      fetch(URLPUBLIC + 'data/pageblogs/blogs.json').then(response => response.json()).then(data => setInfoBlogs(data));
    }, []);
  
    const getPageUpdate = async () => {
      await axios.get(ENDPOINT + 'get-home-page');
    };

    return (
        <>
            <Breadcrumbs title="Blog" pagename="Blog" />
            <section className="py-5 tour_list" >
                <Container>
                    <Row>
                        <Col xl="12" lg="12" md="12" sm="12">
                            <Row>
                                {info_blogs.map((val, inx) => {
                                    return (
                                        <Col xl={3} lg={6} md={6} sm={6} className="mb-5" key={inx}>
                                            <PopularCard val={val} />
                                        </Col>
                                    )
                                })}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Blogs;
