import React from 'react'
import Lightroom from 'react-lightbox-gallery'
import GalleryImg1 from "../../assets/images/gallery/foto1.jpg"
import GalleryImg2 from "../../assets/images/gallery/foto2.jpg"
import GalleryImg3 from "../../assets/images/gallery/foto3.jpg"
import GalleryImg4 from "../../assets/images/gallery/foto4.jpg"
import GalleryImg5 from "../../assets/images/gallery/foto5.jpg"
import GalleryImg6 from "../../assets/images/gallery/foto6.jpg"


const Gallery = () => {
    var images = [
        {
            src: GalleryImg1,
            desc: "Person wearing shoes",
            sub: "Gift Habeshaw"
        },
        {
            src: GalleryImg2,
            desc: "Blonde woman wearing sunglasses smiling at the camera ",
            sub: "Dmitriy Frantsev"
        },
        {
            src: GalleryImg3,
            sub: "Harry Cunningham"
        },
        {
            src: GalleryImg4,
            desc: "Jaipur , Rajasthan India",
            sub: "Liam Baldock"
        },
        {
            src: GalleryImg5,
            sub: "Verne Ho"
        },
        {
            src: GalleryImg6,
            desc: "Rann of kutch , India",
            sub: "Hari Nandakumar"
        },
    ];

    var settings = {
        columnCount: {
            default: 3,
            mobile: 2,
            tab: 3
        },
        mode: "dark",
        enableZoom:true,
    };
    return (
        <Lightroom images={images} settings={settings} />
   );
}

export default Gallery