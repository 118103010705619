import React, { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from "../Loading/Loading";
import "../AdvanceSearch/search.css";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";

registerLocale('es', es);

const AdvanceSearch = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [date_max, setDateMax] = useState(new Date());
  const [branchs, setBranchs] = useState([]);
  const [branch_id, setBranchId] = useState(0);
  const [origins, setOrigins] = useState([]);
  const [origin_id, setOriginId] = useState(0);
  const [destinations, setDestinations] = useState([]);
  const [destination_id, setDestinationId] = useState(0);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

  useEffect(() => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    setDateMax(date);
    getAllBranchs();
    getAllOrigins();
  }, []);

  const selectedBranch = (value) => {
    setBranchId(value);
  }

  const selectedOrigin = (value) => {
    setOriginId(value);
    getAllDestinations(value);
  }

  const selectedDestination = (value) => {
    setDestinationId(value);
  }

  const getAllBranchs = async () => {
    const response = await axios.get(ENDPOINT + 'get-all-branchs-ticket');
    const branchsArray = response.data.data.map(function (element) {
      return {
        label: element.name,
        value: element.branch_id,
      };
    })
    setBranchs(branchsArray);
  };

  const getAllOrigins = async () => {
    const response = await axios.get(ENDPOINT + 'get-all-origins-ticket');
    const originsArray = response.data.data.map(function (element) {
      return {
        label: element.name,
        value: element.destination_id,
      };
    })
    setOrigins(originsArray);
  };

  const getAllDestinations = async (value) => {
    setDestinations([]);
    setLoading(true);
    const response = await axios.get(ENDPOINT + 'get-all-destinations-ticket/' + value);
    const destinationsArray = response.data.data.map(function (element) {
      return {
        label: element.name,
        value: element.destination_id,
      };
    })
    setDestinations(destinationsArray);
    setLoading(false);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let dateFormat = year + '-' + month + '-' + day;
    let branchId = branch_id;
    let originId = origin_id;
    let destinationId = destination_id;
    if (branchId == '' || originId == '' || destinationId == '') {
      Swal.fire({
        icon: 'error',
        title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
        showConfirmButton: true,
        html: '<div class="text-custom-alert">Debe seleccionar las opciones para realizar una busqueda</div>',
        showClass: { popup: 'animate__animated animate__fadeInDown' },
        hideClass: { popup: 'animate__animated animate__fadeOutUp' }
      });
    } else {
      let dataTrip = {
        'branchId': branchId,
        'originId': originId,
        'destinationId': destinationId,
        'dateFormat': dateFormat
      };
      localStorage.setItem('dataTrip', JSON.stringify(dataTrip));
      navigate('/sistema-reserva-viaje/');
    }
  };

  return (
    <>
      {loading && <Loading />}
      <section className="box-search-advance">
        <Container>
          <Row>
            <form onSubmit={handleSearch}>
              <Col md={12} xs={12}>
                <div className="box-search shadow">
                  <div
                    className="item-search">
                    <CustomDropdown
                      label="Base"
                      onSelect={selectedBranch}
                      options={branchs}
                    />
                  </div>
                  <div
                    className="item-search">
                    {<CustomDropdown
                      label="Origen"
                      onSelect={selectedOrigin}
                      options={origins}
                    />}
                  </div>
                  <div
                    className="item-search">
                    <CustomDropdown
                      label="Destino"
                      onSelect={selectedDestination}
                      options={destinations}
                    />
                  </div>
                  <div
                    className="item-search item-search-2">
                    <label className="item-search-label">Fecha</label>
                    <DatePicker
                      locale="es"
                      selected={date}
                      onChange={(date) => setDate(date)}
                      minDate={new Date()}
                      maxDate={date_max}
                      dateFormat="dd, MMMM, yyyy"
                    />
                  </div>
                  <div
                    className="item-search bd-none">
                    <Button type="submit" className="primaryBtn flex-even d-flex justify-content-center">
                      <i className="bi bi-search me-2"></i> Buscar
                    </Button>
                  </div>
                </div>
              </Col>
            </form>
          </Row>
        </Container>
      </section >
    </>
  );
};

export default AdvanceSearch;
