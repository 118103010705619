import React from 'react';
import { Card, Stack } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import "../Cards/card.css";
import ImageNotFound from "../../assets/images/not-found.png";

const PopularCard = ({ val }) => {
  const URLZFILES = process.env.REACT_APP_API_ZFILES;

  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  const date = val.date_created.split(' ')[0];

  return (
    <>
      <Card className="rounded-2 shadow popular">
        <Card.Img
          loading="lazy" 
          variant="top"
          src={val.blog_image ? URLZFILES + val.blog_image : ImageNotFound}
          className="img-fluid"
          alt={"image"}
        />
        <Card.Body>
          <Card.Text>
            <i className="bi bi-geo-alt"></i>
            <span className="text">Oaxaca de Juárez, México</span>
          </Card.Text>
          <Card.Title>
            <NavLink className="body-text text-dark text-decoration-none" to="/tour-details">
              {val.blog_title}
            </NavLink>
          </Card.Title>
          <span className={"badge Blog"}>Blog Nacional</span>
        </Card.Body>
        <Card.Footer className="py-2">
          <Stack
            direction="horizontal"
            className="justify-content-between  mt-2"
          >
            <p>
              <i className="bi bi-clock"></i> {date.split('-')[2]} {months[date.split('-')[1]-1]} {date.split('-')[0]}
            </p>
          </Stack>
        </Card.Footer>
      </Card>
    </>
  )
}

export default PopularCard;