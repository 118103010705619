import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Loading from '../../components/Loading/Loading';
import "./searchtrip.css";

const SearchTrip = () => {
  const navigate = useNavigate();
  const [active1, setActive1] = useState('active');
  const [active2, setActive2] = useState('');
  const [active3, setActive3] = useState('');
  const [active4, setActive4] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState([]);
  const [count_trips, setCountTips] = useState(0);
  const [schedule_id, setScheduleId] = useState(0);
  const [date, setDate] = useState('');
  const [branch_id, setBranchId] = useState(0);
  const [branchName, setBranchName] = useState('');
  const [origin_id, setOriginId] = useState(0);
  const [origin, setOrigin] = useState('');
  const [destination_id, setDestinationId] = useState(0);
  const [destination, setDestination] = useState('');
  const [dateSelect, setDateSelect] = useState('');
  const [timeSelect, setTimeSelect] = useState('');
  const [seats, setSeats] = useState([]);
  const [courtesy, setCourtesy] = useState(2);
  const [extra_luggage, setExtraLuggage] = useState(2);
  const [concept, setConcept] = useState('');
  const [price_extra, setPriceExtra] = useState(0);
  const [price_total, setPriceTotal] = useState(0);
  const [where_up, setWhereUp] = useState('');
  const [available, setAvailable] = useState([]);
  const [priceSelect, setPriceSelect] = useState(0);
  const [classSeat01, setClassSeat01] = useState('');
  const [classSeat02, setClassSeat02] = useState('');
  const [classSeat03, setClassSeat03] = useState('');
  const [classSeat04, setClassSeat04] = useState('');
  const [classSeat05, setClassSeat05] = useState('');
  const [classSeat06, setClassSeat06] = useState('');
  const [classSeat07, setClassSeat07] = useState('');
  const [classSeat08, setClassSeat08] = useState('');
  const [classSeat09, setClassSeat09] = useState('');
  const [classSeat10, setClassSeat10] = useState('');
  const [classSeat11, setClassSeat11] = useState('');
  const [classSeat12, setClassSeat12] = useState('');
  const [classSeat13, setClassSeat13] = useState('');
  const [classSeat14, setClassSeat14] = useState('');
  const [classSeat15, setClassSeat15] = useState('');
  const [classSeat16, setClassSeat16] = useState('');
  const [classSeat17, setClassSeat17] = useState('');
  const [classSeat18, setClassSeat18] = useState('');
  const [classSeat19, setClassSeat19] = useState('');
  const [classSeat20, setClassSeat20] = useState('');
  const [showForm, setShowForm] = useState(false);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

  useEffect(() => {
    window.scrollTo(0, 0);
    let dataTrip = JSON.parse(localStorage.getItem('dataTrip'));
    setBranchId(dataTrip.branchId);
    setOriginId(dataTrip.originId);
    setDestinationId(dataTrip.destinationId);
    setDate(dataTrip.dateFormat);
    searchTrips(dataTrip);
  }, []);

  const handleCloseForm = () => setShowForm(false);
  const handleShowForm = () => setShowForm(true);

  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  const searchTrips = async (dataTrip) => {
    if (dataTrip.branchId == 0 || dataTrip.originId == 0 || dataTrip.destinationId == 0) {
      Swal.fire({
        icon: 'error',
        title: '<div className="title-custom-alert">' + TITLEALERT + '</div>',
        showConfirmButton: true,
        html: '<div className="text-custom-alert">Debe seleccionar las opciones para realizar una busqueda</div>',
        showClass: { popup: 'animate__animated animate__fadeInDown' },
        hideClass: { popup: 'animate__animated animate__fadeOutUp' }
      });
    } else {
      const form = new FormData();
      form.append('branch_id', dataTrip.branchId);
      form.append('origin_id', dataTrip.originId);
      form.append('destination_id', dataTrip.destinationId);
      form.append('date', dataTrip.dateFormat);
      form.append('type', 'web');
      setLoading(true);
      await axios.post(ENDPOINT + 'search-trip', form)
        .then(response => {
          setLoading(false);
          setSearch(response.data.data);
          setOrigin(response.data.data[0].origin);
          setDestination(response.data.data[0].destination);
          setDateSelect(response.data.data.date);
          setCountTips(response.data.data.length);
        }).catch(function (error) {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: '<div className="title-custom-alert">' + TITLEALERT + '</div>',
            showConfirmButton: true,
            timer: 3000,
            html: '<div className="text-custom-alert">' + error.response.data.msg + '</div>',
            showClass: { popup: 'animate__animated animate__fadeInDown' },
            hideClass: { popup: 'animate__animated animate__fadeOutUp' }
          })
        });
    }
  };

  const orderTrips = (type) => {
    setLoading(true);
    setActive1('');
    setActive2('');
    setActive3('');
    setActive4('');
    let searchArray = [...search];
    if (type == 1) {
      setActive1('active');
      searchArray.sort((a, b) => (a.price < b.price) ? 1 : -1);
    } else if (type == 2) {
      setActive2('active');
      searchArray.sort((a, b) => (a.price > b.price) ? 1 : -1);
    } else if (type == 3) {
      setActive3('active');
      searchArray.sort((a, b) => (a.time > b.time) ? 1 : -1);
    } else if (type == 4) {
      setActive4('active');
      searchArray.sort((a, b) => (a.time < b.time) ? 1 : -1);
    }
    setSearch(searchArray);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let user_id = 1;
    const form = new FormData();
    data.filter((dataprice) => dataprice.price_ticket = priceSelect);
    const dataForm = JSON.stringify(data);
    if (branch_id == 0 || origin_id == 0 || destination_id == 0 || dataForm == []) {
      Swal.fire({
        icon: 'error',
        title: '<div className="title-custom-alert">' + TITLEALERT + '</div>',
        showConfirmButton: true,
        html: '<div className="text-custom-alert">Debe proporcionar los datos del viaje</div>',
        showClass: { popup: 'animate__animated animate__fadeInDown' },
        hideClass: { popup: 'animate__animated animate__fadeOutUp' }
      });
    } else {
      form.append('branch_id', branch_id);
      form.append('origin_id', origin_id);
      form.append('destination_id', destination_id);
      form.append('bus_id', schedule_id);
      form.append('user_id', user_id);
      form.append('date', dateSelect);
      form.append('time', timeSelect);
      form.append('data', dataForm);
      form.append('where_up', where_up);
      form.append('courtesy', courtesy);
      form.append('status', 4);
      form.append('extra_luggage', extra_luggage);
      form.append('concept', concept);
      form.append('price_extra', price_extra);
      form.append('type', 2);
      setLoading(true);
      await axios.post(ENDPOINT + 'save-ticket', form).then(response => {
        let dataResume = {
          'dataForm': JSON.parse(dataForm),
          'origin': origin,
          'destination': destination,
          'date': dateSelect,
          'time': timeSelect,
          'total': price_total,
          'purchase_order': response.data.data
        };
        setData([]);
        resetBooking();
        resetSeats();
        handleCloseForm();
        localStorage.setItem('dataResume', JSON.stringify(dataResume));
        navigate('/realizar-pago');
      }).catch(function (error) {
        setLoading(false);
        console.log(error);
        Swal.fire({
          icon: 'error',
          title: '<div class="title-custom-alert">' + TITLEALERT + '</div>',
          showConfirmButton: true,
          html: '<div class="text-custom-alert">Debe proporcionar los datos del viaje</div>',
          showClass: { popup: 'animate__animated animate__fadeInDown' },
          hideClass: { popup: 'animate__animated animate__fadeOutUp' }
        })
      });
    }
  };

  const handleChooseTrip = async (schedule_id, origin, destination, date, time, available, branchName, price) => {
    getAllBookingSeats(schedule_id, date);
    setScheduleId(schedule_id);
    setOrigin(origin);
    setDestination(destination);
    setDateSelect(date);
    setTimeSelect(time);
    setAvailable(available);
    setBranchName(branchName);
    setPriceSelect(price);
    setData([]);
    handleShowForm();
  };

  const resetSeats = () => {
    (classSeat01 === 'seat-taken') ? setClassSeat01('seat-taken') : setClassSeat01('seat-available');
    (classSeat02 === 'seat-taken') ? setClassSeat02('seat-taken') : setClassSeat02('seat-available');
    (classSeat03 === 'seat-taken') ? setClassSeat03('seat-taken') : setClassSeat03('seat-available');
    (classSeat04 === 'seat-taken') ? setClassSeat04('seat-taken') : setClassSeat04('seat-available');
    (classSeat05 === 'seat-taken') ? setClassSeat07('seat-taken') : setClassSeat05('seat-available');
    (classSeat06 === 'seat-taken') ? setClassSeat06('seat-taken') : setClassSeat06('seat-available');
    (classSeat07 === 'seat-taken') ? setClassSeat07('seat-taken') : setClassSeat07('seat-available');
    (classSeat08 === 'seat-taken') ? setClassSeat08('seat-taken') : setClassSeat08('seat-available');
    (classSeat09 === 'seat-taken') ? setClassSeat09('seat-taken') : setClassSeat09('seat-available');
    (classSeat10 === 'seat-taken') ? setClassSeat10('seat-taken') : setClassSeat10('seat-available');
    (classSeat11 === 'seat-taken') ? setClassSeat11('seat-taken') : setClassSeat11('seat-available');
    (classSeat12 === 'seat-taken') ? setClassSeat12('seat-taken') : setClassSeat12('seat-available');
    (classSeat13 === 'seat-taken') ? setClassSeat13('seat-taken') : setClassSeat13('seat-available');
    (classSeat14 === 'seat-taken') ? setClassSeat14('seat-taken') : setClassSeat14('seat-available');
    (classSeat15 === 'seat-taken') ? setClassSeat15('seat-taken') : setClassSeat15('seat-available');
    (classSeat16 === 'seat-taken') ? setClassSeat16('seat-taken') : setClassSeat16('seat-available');
    (classSeat17 === 'seat-taken') ? setClassSeat17('seat-taken') : setClassSeat17('seat-available');
    (classSeat18 === 'seat-taken') ? setClassSeat18('seat-taken') : setClassSeat18('seat-available');
    (classSeat19 === 'seat-taken') ? setClassSeat19('seat-taken') : setClassSeat19('seat-available');
    (classSeat20 === 'seat-taken') ? setClassSeat20('seat-taken') : setClassSeat20('seat-available');
  };

  const getAllBookingSeats = async (schedule_id, date) => {
    setLoading(true);
    const form = new FormData();
    form.append('schedule_id', schedule_id);
    form.append('date', date);
    await axios.post(ENDPOINT + "get-all-booking-seats", form).then(response => {
      setLoading(false);
      let takens = response.data.data;
      takens.forEach(element => {
        if (element.seat === 1) {
          setClassSeat01('seat-taken');
        }
        if (element.seat === 2) {
          setClassSeat02('seat-taken');
        }
        if (element.seat === 3) {
          setClassSeat03('seat-taken');
        }
        if (element.seat === 4) {
          setClassSeat04('seat-taken');
        }
        if (element.seat === 5) {
          setClassSeat05('seat-taken');
        }
        if (element.seat === 6) {
          setClassSeat06('seat-taken');
        }
        if (element.seat === 7) {
          setClassSeat07('seat-taken');
        }
        if (element.seat === 8) {
          setClassSeat08('seat-taken');
        }
        if (element.seat === 9) {
          setClassSeat09('seat-taken');
        }
        if (element.seat === 10) {
          setClassSeat10('seat-taken');
        }
        if (element.seat === 11) {
          setClassSeat11('seat-taken');
        }
        if (element.seat === 12) {
          setClassSeat12('seat-taken');
        }
        if (element.seat === 13) {
          setClassSeat13('seat-taken');
        }
        if (element.seat === 14) {
          setClassSeat14('seat-taken');
        }
        if (element.seat === 15) {
          setClassSeat15('seat-taken');
        }
        if (element.seat === 16) {
          setClassSeat16('seat-taken');
        }
        if (element.seat === 17) {
          setClassSeat17('seat-taken');
        }
        if (element.seat === 18) {
          setClassSeat18('seat-taken');
        }
        if (element.seat === 19) {
          setClassSeat19('seat-taken');
        }
        if (element.seat === 20) {
          setClassSeat20('seat-taken');
        }
      });
    }).catch(function (error) {
      setLoading(false);
      console.log(error)
    });
  };

  const handleSeat = (value) => {
    let resume = {
      name: "",
      last_name: "",
      email: "",
      phone: "",
      seat: value,
      price_ticket: ""
    };
    resetSeats();
    getAllBookingSeats(schedule_id, dateSelect);
    if (!seats.includes(value)) {
      seats.push(value);
      data.push(resume);
    } else {
      const index = seats.indexOf(value);
      if (index > -1) {
        seats.splice(index, 1);
        removePassager(index);
      }
    }
    setSeats(seats);
    seats.forEach(element => {
      if (element === 1) {
        setClassSeat01('seat-selected');
      }
      if (element === 2) {
        setClassSeat02('seat-selected');
      }
      if (element === 3) {
        setClassSeat03('seat-selected');
      }
      if (element === 4) {
        setClassSeat04('seat-selected');
      }
      if (element === 5) {
        setClassSeat05('seat-selected');
      }
      if (element === 6) {
        setClassSeat06('seat-selected');
      }
      if (element === 7) {
        setClassSeat07('seat-selected');
      }
      if (element === 8) {
        setClassSeat08('seat-selected');
      }
      if (element === 9) {
        setClassSeat09('seat-selected');
      }
      if (element === 10) {
        setClassSeat10('seat-selected');
      }
      if (element === 11) {
        setClassSeat11('seat-selected');
      }
      if (element === 12) {
        setClassSeat12('seat-selected');
      }
      if (element === 13) {
        setClassSeat13('seat-selected');
      }
      if (element === 14) {
        setClassSeat14('seat-selected');
      }
      if (element === 15) {
        setClassSeat15('seat-selected');
      }
      if (element === 16) {
        setClassSeat16('seat-selected');
      }
      if (element === 17) {
        setClassSeat17('seat-selected');
      }
      if (element === 18) {
        setClassSeat18('seat-selected');
      }
      if (element === 19) {
        setClassSeat19('seat-selected');
      }
      if (element === 20) {
        setClassSeat20('seat-selected');
      }
    });
    totalPrice();
  };

  const removePassager = (index) => {
    const filterPassager = [...data];
    filterPassager.splice(index, 1);
    setData(filterPassager);
  };

  const resetBooking = () => {
    setData([]);
    resetSeats();
    getAllBookingSeats(schedule_id, dateSelect);
  };

  const onChange = (e, index) => {
    const updateBooking = data.map((booking, i) =>
      index === i ? Object.assign(booking, { [e.target.name]: e.target.value }) : booking,
    );
    setData(updateBooking);
  };

  const totalPrice = () => {
    let suma = 0;
    data.forEach(function (element) {
      suma += parseInt(priceSelect);
    });
    setPriceTotal(suma);
  }

  const cancelTicket = () => {
    setData([]);
    handleCloseForm();
    getAllBookingSeats(0, dateSelect);
    setClassSeat01('seat-available');
    setClassSeat02('seat-available');
    setClassSeat03('seat-available');
    setClassSeat04('seat-available');
    setClassSeat05('seat-available');
    setClassSeat06('seat-available');
    setClassSeat07('seat-available');
    setClassSeat08('seat-available');
    setClassSeat09('seat-available');
    setClassSeat10('seat-available');
    setClassSeat11('seat-available');
    setClassSeat12('seat-available');
    setClassSeat13('seat-available');
    setClassSeat14('seat-available');
    setClassSeat15('seat-available');
    setClassSeat16('seat-available');
    setClassSeat17('seat-available');
    setClassSeat18('seat-available');
    setClassSeat19('seat-available');
    setClassSeat20('seat-available');
  };

  const handleChangeCourtesy = (e) => {
    setCourtesy(e.target.value);
  };

  const handleChangesetExtraLuggage = (e) => {
    setExtraLuggage(e.target.value);
  };

  const handleChangePriceExtra = (value) => {
    let priceextra = parseInt(value);
    setPriceExtra(priceextra);
    let pricetotal = price_total + priceextra;
    setPriceTotal(pricetotal);
  };

  return (
    <>

      {loading && <Loading />}

      <Modal size="xl"
        show={showForm}
        onHide={cancelTicket}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="bg-header-modal">
          <Modal.Title>{branchName} | {origin} - {destination}  | Fecha: {dateSelect} Hora: {timeSelect}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="formulario" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 col-sm-4 container-legend">
                  <div className="card">
                    <div className="card-header">
                      <h4 className="card-title font-bold m-0">
                        Escoga su asiento
                      </h4>
                      {/*<div className="d-flex justify-content-between align-items-center">
                        <div
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Disponible">
                          <span className="text-center">Disponible</span>
                          <div className="seat seat-available"></div>
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Seleccionado">
                          <span className="text-center">Seleccionado</span>
                          <div className="seat seat-selected"></div>
                        </div>
                        <div
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Ocupado">
                          <span className="text-center">Ocupado</span>
                          <div className="seat seat-taken"></div>
                        </div>
                      </div>*/}
                      <div class="legend">
                        <div class="legend-item">
                          <div class="seat legend-color seat-available"></div>
                          <span class="legend-label">Disponible</span>
                        </div>
                        <div class="legend-item">
                          <div class="seat legend-color seat-selected"></div>
                          <span class="legend-label">Seleccionado</span>
                        </div>
                        <div class="legend-item">
                          <div class="seat legend-color seat-taken"></div>
                          <span class="legend-label">Ocupado</span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justity-content-between align-content-center">
                        <table className="w-100">
                          <tbody className="container-bus">
                            {available === 14 ?
                              <>
                                <tr>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Conductor"
                                    colspan="2"
                                    className="volante-bus mb-2">
                                  </td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 14"
                                    onClick={() => handleSeat(14)}
                                    className={`seat ${classSeat14}`}>
                                    <span>14</span>
                                  </td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 1"
                                    onClick={() => handleSeat(1)}
                                    className={`seat ${classSeat01}`}>
                                    <span>1</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 2"
                                    onClick={() => handleSeat(2)}
                                    className={`seat ${classSeat02}`}>
                                    <span>2</span>
                                  </td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 3"
                                    onClick={() =>
                                      handleSeat(3)}
                                    className={`seat ${classSeat03}`}>
                                    <span>3</span>
                                  </td>
                                  <td colspan="2" className=""></td>
                                </tr>
                                <tr>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 4"
                                    onClick={() => handleSeat(4)}
                                    className={`seat ${classSeat04}`}>
                                    <span>4</span>
                                  </td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 5"
                                    onClick={() => handleSeat(5)}
                                    className={`seat ${classSeat05}`}>
                                    <span>5</span>
                                  </td>
                                  <td className=""></td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 6"
                                    onClick={() => handleSeat(6)}
                                    className={`seat ${classSeat06}`}>
                                    <span>6</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 7"
                                    onClick={() => handleSeat(7)}
                                    className={`seat ${classSeat07}`}>
                                    <span>7</span>
                                  </td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 8"
                                    onClick={() =>
                                      handleSeat(8)}
                                    className={`seat ${classSeat08}`}>
                                    <span>8</span>
                                  </td>
                                  <td className=""></td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 9"
                                    onClick={() => handleSeat(9)}
                                    className={`seat ${classSeat09}`}>
                                    <span>9</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 10"
                                    onClick={() => handleSeat(10)}
                                    className={`seat ${classSeat10}`}>
                                    <span>10</span>
                                  </td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 11"
                                    onClick={() => handleSeat(11)}
                                    className={`seat ${classSeat11}`}>
                                    <span>11</span>
                                  </td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 12"
                                    onClick={() =>
                                      handleSeat(12)}
                                    className={`seat ${classSeat12}`}>
                                    <span>12</span>
                                  </td>
                                  <td
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Puesto 13"
                                    onClick={() => handleSeat(13)}
                                    className={`seat ${classSeat13}`}>
                                    <span>13</span>
                                  </td>
                                </tr>
                              </>
                              : available === 20 ?
                                <>
                                  <tr>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Conductor"
                                      colspan="2"
                                      className="volante-bus mb-2">
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 20"
                                      onClick={() => handleSeat(20)}
                                      className={`seat ${classSeat20}`}>
                                      <span>20</span>
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 1"
                                      onClick={() => handleSeat(1)}
                                      className={`seat ${classSeat01}`}>
                                      <span>1</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 2"
                                      onClick={() => handleSeat(2)}
                                      className={`seat ${classSeat02}`}>
                                      <span>2</span>
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 3"
                                      onClick={() => handleSeat(3)}
                                      className={`seat ${classSeat03}`}>
                                      <span>3</span>
                                    </td>
                                    <td colspan="2" className=""></td>
                                  </tr>
                                  <tr>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 4"
                                      onClick={() => handleSeat(4)}
                                      className={`seat ${classSeat04}`}>
                                      <span>4</span>
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 5"
                                      onClick={() => handleSeat(5)}
                                      className={`seat ${classSeat05}`}>
                                      <span>5</span>
                                    </td>
                                    <td className=""></td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 6"
                                      onClick={() => handleSeat(6)}
                                      className={`seat ${classSeat06}`}>
                                      <span>6</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 7"
                                      onClick={() => handleSeat(7)}
                                      className={`seat ${classSeat07}`}>
                                      <span>7</span>
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 8"
                                      onClick={() => handleSeat(8)}
                                      className={`seat ${classSeat08}`}>
                                      <span>8</span>
                                    </td>
                                    <td className=""></td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 9"
                                      onClick={() => handleSeat(9)}
                                      className={`seat ${classSeat09}`}>
                                      <span>9</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 10"
                                      onClick={() => handleSeat(10)}
                                      className={`seat ${classSeat10}`}>
                                      <span>10</span>
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 11"
                                      onClick={() => handleSeat(11)}
                                      className={`seat ${classSeat11}`}>
                                      <span>11</span>
                                    </td>
                                    <td className=""></td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 12"
                                      onClick={() => handleSeat(12)}
                                      className={`seat ${classSeat12}`}>
                                      <span>12</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 13"
                                      onClick={() => handleSeat(13)}
                                      className={`seat ${classSeat13}`}>
                                      <span>13</span>
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 14"
                                      onClick={() => handleSeat(14)}
                                      className={`seat ${classSeat14}`}>
                                      <span>14</span>
                                    </td>
                                    <td className=""></td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 15"
                                      onClick={() => handleSeat(15)}
                                      className={`seat ${classSeat15}`}>
                                      <span>15</span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 16"
                                      onClick={() => handleSeat(16)}
                                      className={`seat ${classSeat16}`}>
                                      <span>16</span>
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 17"
                                      onClick={() => handleSeat(17)}
                                      className={`seat ${classSeat17}`}>
                                      <span>17</span>
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 18"
                                      onClick={() => handleSeat(18)}
                                      className={`seat ${classSeat18}`}>
                                      <span>18</span>
                                    </td>
                                    <td
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Puesto 19"
                                      onClick={() => handleSeat(19)}
                                      className={`seat ${classSeat19}`}>
                                      <span>19</span>
                                    </td>
                                  </tr>
                                </>
                                : ''}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-8 container-passenger">
                  <div className="card">
                    <div className="card-header">
                      <div className="indications-header row">
                        <div className="col-md-7">
                          <h4 className="card-title m-0 page-title">
                            <i className="fa-solid fa-money-check-dollar me-2"></i>
                            Información Boleta
                          </h4>
                        </div>
                        <div className="col-md-5 text-right">
                          <h4 className="m-0 page-title" style={{ float: "right" }}>
                            TOTAL ${price_total}MXN
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      {data.map((value, index) =>
                        <div className="row" key={index}>
                          <div className="col-12 col-sm-12">
                            <p className="text-white p-2 bg-passager">
                              Datos del pasajero  |  Puesto : {value.seat}
                            </p>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group mt-2 mb-2">
                              <input
                                className={`form-control form-control-sm`}
                                placeholder="Nombres del pasajero"
                                type="text"
                                onChange={(e) => onChange(e, index)}
                                id="name"
                                name="name"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group mt-2 mb-2">
                              <input
                                className={`form-control form-control-sm`}
                                placeholder="Apellidos del pasajero"
                                type="text"
                                onChange={(e) => onChange(e, index)}
                                id="last_name"
                                name="last_name"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="form-group mt-2 mb-2">
                              <input
                                className={`form-control form-control-sm`}
                                placeholder="Email"
                                type="email"
                                onChange={(e) => onChange(e, index)}
                                id="email"
                                name="email"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-3">
                            <div className="form-group mt-2 mb-2">
                              <input
                                className={`form-control form-control-sm`}
                                placeholder="Teléfono"
                                type="tel"
                                onChange={(e) => onChange(e, index)}
                                id="phone"
                                name="phone"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-3">
                            <div className="form-group mt-2 mb-2">
                              <input
                                className={`form-control form-control-sm`}
                                placeholder="Precio"
                                type="number"
                                onChange={(e) => onChange(e, index)}
                                id="price_ticket"
                                name="price_ticket"
                                required
                                value={priceSelect}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <hr />
                      <div className={`row ${data.length === 0 ? 'd-none' : ''}`}>
                        <div className="col-12 col-sm-6">
                          <div className="form-group mt-2 mb-2">
                            <label htmlFor="where_up" className="col-form-label col-form-label-sm">
                              ¿Dónde suben?
                            </label>
                            <input
                              className={`form-control form-control-sm`}
                              placeholder="¿Donde suben? (Opcional)"
                              type='text'
                              value={where_up}
                              onChange={(e) => setWhereUp(e.target.value)}
                              id="where_up"
                              name="where_up"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-3 visibility-hidden">
                          <div className="form-group mt-2 mb-2" >
                            <label htmlFor="courtesy" className="col-form-label col-form-label-sm">
                              Cortesia
                            </label>
                            <div onChange={handleChangeCourtesy}>
                              <input
                                type="radio"
                                value="1"
                                name="courtesy"
                              /> <span style={{ fontSize: "12px" }}>SI</span>
                              <br />
                              <input
                                type="radio"
                                value="2"
                                name="courtesy"
                                defaultChecked
                              /> <span style={{ fontSize: "12px" }}>NO</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-3 visibility-hidden">
                          <div className="form-group mt-2 mb-2" >
                            <label htmlFor="extra_luggage" className="col-form-label col-form-label-sm">
                              Equipaje extra
                            </label>
                            <div onChange={handleChangesetExtraLuggage}>
                              <input
                                type="radio"
                                value="1"
                                name="extra_luggage"
                              /> <span style={{ fontSize: "12px" }}>SI</span>
                              <br />
                              <input
                                type="radio"
                                value="2"
                                name="extra_luggage"
                                defaultChecked
                              /> <span style={{ fontSize: "12px" }}>NO</span>
                            </div>
                          </div>
                        </div>
                        <div className={`${extra_luggage == 2 ? 'visibility-hidden' : 'visibility-visible'} col-12 col-sm-6`}>
                          <label htmlFor="concept" className="col-form-label col-form-label-sm">
                            Concepto
                          </label>
                          <input
                            className={`form-control form-control-sm`}
                            placeholder="Ingrese concepto (Opcional)"
                            type='text'
                            value={concept}
                            onChange={(e) => setConcept(e.target.value)}
                            id="concept"
                            name="concept"
                          />
                        </div>
                        <div className={`${extra_luggage == 2 ? 'visibility-hidden' : 'visibility-visible'} col-12 col-sm-3`}>
                          <label htmlFor="price_extra" className="col-form-label col-form-label-sm">
                            Precio extra
                          </label>
                          <input
                            className={`form-control form-control-sm`}
                            placeholder="Precio extra"
                            type='text'
                            value={price_extra}
                            onChange={(e) => handleChangePriceExtra(e.target.value)}
                            id="price_extra"
                            name="price_extra"
                          />
                        </div>
                        <div className="col-12 col-sm-3 d-none">
                          <div className="form-group" >
                            <label htmlFor="price_total" className="col-form-label col-form-label-sm">
                              Precio Total
                            </label>
                            <input
                              className={`form-control form-control-sm`}
                              placeholder="Precio Total"
                              type='text'
                              value={price_total}
                              id="price_total"
                              name="price_total"
                              required
                              readOnly={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="primaryBtn">
                <i className="bi bi-credit-card me-2"></i>
                Ir a pagar
              </button>
              {/*<button
                onClick={cancelTicket}
                type="button"
                className="btn btn-danger"
                data-dismiss="modal">
                <i className="bi bi-x-circle me-2"></i>
                Cancelar
              </button>*/}
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <Breadcrumbs title="Resultado de busqueda" pagename="Resultado de busqueda" />

      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
            <div className="main_heading text-center">
              <h1>Selecciona tu viaje de {origin} a {destination}, {date.split('-')[2]} de {months[date.split('-')[1] - 1]} del {date.split('-')[0]}</h1>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div className="col-md-6 text-center">
                <div className="btn-group mr-2">
                  <button className={`btn btn-outline-primary ${active1}`} onClick={() => orderTrips(1)}>Más temprano</button>
                  <button className={`btn btn-outline-primary ${active2}`} onClick={() => orderTrips(2)}>Más tarde</button>
                  <button className={`btn btn-outline-primary ${active3}`} onClick={() => orderTrips(3)}>Menor precio</button>
                  <button className={`btn btn-outline-primary ${active4}`} onClick={() => orderTrips(4)}>Mayor precio</button>
                </div>
              </div>
              <div className="col-md-6 text-center">
                Buses disponibles: {count_trips}
              </div>
            </div>
            <div className="p-2 main-content">


              {/*<div className="seat-picker">
                <SeatPicker
                  addSeatCallback={addSeatCallback}
                  removeSeatCallback={removeSeatCallback}
                  rows={rows}
                  maxReservableSeats={14}
                  alpha
                  visible
                  selectedByDefault
                  loading={false}
                  tooltipProps={{ multiline: true }}
                />
              </div>
              <div className="legend">
                <div className="legend-item">
                  <div className="seat available"></div>
                  <span>Disponible</span>
                </div>
                <div className="legend-item">
                  <div className="seat selected"></div>
                  <span>Seleccionado</span>
                </div>
                <div className="legend-item">
                  <div className="seat reserved"></div>
                  <span>Reservado</span>
                </div>
              </div>
              <div className="selected-seats">
                <h2>Asientos seleccionados:</h2>
                <p>{selected.join(', ')}</p>
              </div>*/}



              {search.map((item, index) => (
                <div class="card shadow mt-4" key={index}>
                  <div class="card-body search-trip-detail">
                    <div class="row align-items-center">
                      <div class="col-12 mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                          <h5 class="mb-0 page-title-bus">
                            <i class="bi bi-bus-front me-2 font-bold" aria-hidden="true"></i>
                            {item.name_unit}
                          </h5>
                          <div>
                            <span class="badge me-1 service-bus" title="WiFi"><i class="bi bi-wifi" aria-hidden="true"></i><span class="visually-hidden">Wi-Fi</span></span>
                            <span class="badge me-1 service-bus" title="Toma corriente"><i class="bi bi-plug" aria-hidden="true"></i><span class="visually-hidden">Toma Corriente</span></span>
                            <span class="badge me-1 service-bus" title="Aire acondicionado"><i class="bi bi-snow" aria-hidden="true"></i><span class="visually-hidden">Aire Acondicionado</span></span>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 mb-3 mt-3">
                        <div class="d-flex justify-content-between align-items-center">
                          <div className="text-center">
                            <i class="bi bi-crosshair"></i> <br />
                            <h3 class="mb-0 mt-2 page-title-bus">{origin}</h3>
                          </div>
                          <div class="d-flex align-items-center flex-grow-1">
                            <div class="bus-line">
                              <div class="bus-icon text-center">

                                <i className="bi bi-alarm"></i> <br />
                                <label className="mt-3" htmlFor="time">
                                  {item.time}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="text-end">
                            <div className="text-center">
                              <i class="bi bi-geo-alt"></i>
                            </div><br />
                            <h3 class="mb-0 page-title-bus">{destination}</h3>
                          </div>
                        </div>
                      </div>

                      <hr className="mt-5" />

                      <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 class="mb-0 page-title-bus">${item.price}MXN</h4>
                          </div>
                          <div class="text-muted text-center">
                            <span>{item.seats} Disponibles</span>
                          </div>
                          <button
                            class="primaryBtn"
                            data-placement="top"
                            title="Elegir viaje"
                            onClick={() => handleChooseTrip(item.schedule_id, item.origin, item.destination, item.date, item.time, item.available, item.base, item.price)}
                          >
                            Seleccionar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/*search.map((item, index) => (
                <div className="card mt-4" key={index}>
                  <div className="card-body">
                    <div className="indications row mt-3">
                      <div className="col-sm-4 text-center search-trip-detail">
                        <div className="mb-4 mt-2 font-bold">
                          Unidad
                        </div>
                        <i className="bi bi-truck-front"></i><br />
                        <label className="col-form-label" htmlFor="unit">
                          {item.name_unit}
                        </label>
                      </div>
                      <div className="col-sm-2 text-center search-trip-detail">
                        <div className="mb-4 mt-2 font-bold">
                          Hora Salida
                        </div>
                        <i className="bi bi-alarm"></i><br />
                        <label className="col-form-label" htmlFor="time">
                          {item.time}
                        </label>
                      </div>
                      <div className="col-sm-2 text-center search-trip-detail">
                        <div className="mb-4 mt-2 font-bold">
                          Asientos
                        </div>
                        <i className="bi bi-people"></i><br />
                        <label className="col-form-label" htmlFor="available">
                          {item.available}
                        </label>
                      </div>
                      <div className="col-sm-2 text-center search-trip-detail">
                        <div className="mb-4 mt-2 font-bold">
                          Disponible
                        </div>
                        <i className="bi bi-person-plus"></i><br />
                        <label className="col-form-label" htmlFor="seats">
                          {item.seats}
                        </label>
                      </div>
                      <div className="col-sm-2 d-flex justify-content-center align-items-center">
                        <button
                          data-placement="top"
                          title="Elegir viaje"
                          onClick={() => handleChooseTrip(item.schedule_id, item.origin, item.destination, item.date, item.time, item.available, item.base, item.price)}
                          className='btn btn-success btn-sm'>
                          <i className="bi bi-cash-coin"></i><br />
                          ${item.price}MXN
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchTrip;
