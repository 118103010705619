import React, { useEffect, useState } from 'react';
import Banner from "../../components/Banner/Banner";
import Services from "../../components/Services/Services";
import AdvanceSearch from "../../components/AdvanceSearch/AdvanceSearch";
import { Container, Row, Col, Card } from "react-bootstrap";
import Slider from "react-slick";
import Gallery from "../../components/Gallery/Gallery";
import Cards from "../../components/Cards/Cards";
import PopularCard from "../../components/Cards/PopularCard";
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Video from '../../assets/videos/grupo-viala.mp4';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import "../About/about.css";
import ImageNotFound from "../../assets/images/not-found.png";

const Home = () => {
  const [info_about, setInfoAbout] = useState([]);
  const [info_counts, setCounts] = useState([]);
  const [info_destination, setInfoDestination] = useState([]);
  const [info_destinations, setInfoDestinations] = useState([]);
  const [info_discover, setDiscover] = useState([]);
  const [info_blog, setInfoBlog] = useState([]);
  const [info_blogs, setInfoBlogs] = useState([]);
  const [show, setShow] = useState(false);
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const URLZFILES = process.env.REACT_APP_API_ZFILES;
  const URLPUBLIC = process.env.REACT_APP_PUBLIC;

  useEffect(() => {
    window.scrollTo(0, 0);
    getPageUpdate();
    fetch(URLPUBLIC + 'data/pageabout/abouts.json').then(response => response.json()).then(data => setInfoAbout(data));
    fetch(URLPUBLIC + 'data/pagehome/counts.json').then(response => response.json()).then(data => setCounts(data));
    fetch(URLPUBLIC + 'data/pagehome/destination.json').then(response => response.json()).then(data => setInfoDestination(data[0]));
    fetch(URLPUBLIC + 'data/pagedestinations/destinations.json').then(response => response.json()).then(data => setInfoDestinations(data));
    fetch(URLPUBLIC + 'data/pagehome/discover.json').then(response => response.json()).then(data => setDiscover(data[0]));
    fetch(URLPUBLIC + 'data/pageblogs/blogs.json').then(response => response.json()).then(data => setInfoBlogs(data));
    fetch(URLPUBLIC + 'data/pagehome/blog.json').then(response => response.json()).then(data => setInfoBlog(data[0]));
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getPageUpdate = async () => {
    await axios.get(ENDPOINT + 'get-home-page');
  };

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          autoplay: true,
          prevArrow: false,
          nextArrow: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  return (
    <>
      <Banner />
      <AdvanceSearch />
      <section className="py-5">
        <Container>
          <Row>
            <Col md="8">
              <div className="about-content">
                <div className="about-image position-relative">
                  <img loading="lazy" src={info_about.about_image ? URLZFILES + info_about.about_image : ImageNotFound} alt="Nosotros" className="img-fluid rounded-5" />
                  <div className="about-image-content position-absolute top-50 end-0 p-md-4 p-3 rounded-5 shadow-sm">
                    <h3 className="h2 fw-bold text-white text-uppercase text-center">{info_about.about_sub_title}</h3>
                  </div>
                </div>
              </div>
              <h2 className="h2 font-bold pt-4 pb-2">
                {info_about.about_title}
              </h2>
              <div dangerouslySetInnerHTML={{ __html: info_about.about_description }} />
            </Col>
            <Col md="4">
              <Card className="border shadow rounded-3 mb-4">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-item-search my-2">
                    <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                      <img loading="lazy" height={64} width={64} src={info_about.about_indicator_icon_trip ? URLZFILES + info_about.about_indicator_icon_trip : ImageNotFound} alt="icon" className="img-fluid" />
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">+{info_counts.tickets}</Card.Title>
                  <p className="mb-2 body-text">
                    {info_about.about_indicator_text_trip}
                  </p>
                </Card.Body>
              </Card>

              <Card className="border shadow rounded-3 mb-4">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-item-search my-2">
                    <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                      <img loading="lazy" height={64} width={64} src={info_about.about_indicator_icon_package ? URLZFILES + info_about.about_indicator_icon_package : ImageNotFound} alt="icon" className="img-fluid" />
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">+{info_counts.packages}</Card.Title>
                  <p className="mb-2 body-text">
                    {info_about.about_indicator_text_package}
                  </p>
                </Card.Body>
              </Card>

              <Card className="border shadow rounded-3 mb-4">
                <Card.Body className="text-center">
                  <div className="d-flex justify-content-center align-item-search my-2">
                    <div className="rounded-circle bg-light shadow-sm bg-opacity-10 p-2">
                      <img loading="lazy" height={64} width={64} src={info_about.about_indicator_icon_unit ? URLZFILES + info_about.about_indicator_icon_unit : ImageNotFound} alt="icon" className="img-fluid" />
                    </div>
                  </div>
                  <Card.Title className="fw-bold h5">+{info_counts.uniits}</Card.Title>
                  <p className="mb-2 body-text">
                    {info_about.about_indicator_text_unit}
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <Services />
      <section className="tours_section slick_slider">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading text-center">
                <h1 className="text-uppercase">{info_destination.destination_title}</h1>
                <h6 className="h6 text-uppercase mt-3">{info_destination.destination_sub_title}</h6>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Slider {...settings}>
                {info_destinations.map((destination, inx) => {
                  return (
                    <Cards destination={destination} key={inx} />
                  );
                })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="call_us">
        <Container>
          <Row className="align-items-center">
            <Col md="8">
              <h2 className="heading text-uppercase">{info_discover.discover_title}</h2>
              <h4 className="h4 text-uppercase mt-2 text-white">{info_discover.discover_sub_title}</h4>
              <p className="text">
                <div dangerouslySetInnerHTML={{ __html: info_discover.discover_description }} />
              </p>
            </Col>
            <Col md="4" className="text-center mt-3 mt-md-0">
              <div className="video">
                <button
                  type="button"
                  className="btn-play"
                  data-src="https://www.youtube.com/embed/DWRcNpR6Kdc"
                  onClick={handleShow}>
                  <span></span>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="overlay"></div>
      </section>
      <Modal className="bg-modal" size="md" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton className="bg-header-modal">
          <Modal.Title>Nuestras Unidades</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video
            src={Video}
            width="100%"
            height="300px"
            controls
            autoPlay
            muted
            type="video/mp4"
          />
        </Modal.Body>

      </Modal>
      <section className="popular py-5">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading text-center">
                <h1 className="text-uppercase">{info_blog.blog_title}</h1>
                <h6 className="h6 text-uppercase mt-3">{info_blog.blog_sub_title}</h6>
              </div>
            </Col>
          </Row>
          <Row>
            {info_blogs.map((val, inx) => {
              return (
                <Col md={3} sm={6} xs={12} className="mb-5" key={inx}>
                  <PopularCard val={val} />
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
      {/*<section className="gallery">
        <Container>
          <Row>
            <Col md="12">
              <div className="main_heading text-center">
                <h1 className="text-uppercase">Galeria de fotos</h1>
                <h6 className="h6 text-uppercase mt-3">Te presentamos nuestras unidades</h6>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Gallery />
            </Col>
          </Row>
        </Container>
      </section>*/}
    </>
  );
};

export default Home;
