import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { X } from 'react-feather';
import './busbooking.css';
import SeatSelected from '../../assets/images/seat_selected.svg';
import SeatTaken from '../../assets/images/seat_taken.svg';
import SeatAvailable from '../../assets/images/seat_available.svg';
import Volante from '../../assets/images/volante.png';

const BusBooking = () => {
    const [selectedSeats, setSelectedSeats] = useState([]);
    const [forms, setForms] = useState({});
    const [total, setTotal] = useState(0);
    const [petTotal, setPetTotal] = useState(0);
    const [luggageTotal, setLuggageTotal] = useState(0);
    const [generalInfo, setGeneralInfo] = useState({
        pickupLocation: '',
        notes: '',
        courtesy: 'no',
        cardPayment: 'no',
        extraLuggage: 'no',
        extraLuggageAmount: '0',
        pet: 'no',
        petAmount: '0',
        transactionType: 'comprar'
    });

    // Define bus layout
    const busLayout = {
        row1: [1, 2, null, 3, 4],
        row2: [5, 6, null, 7, 8],
        row3: [9, 10, null, 11, 12],
        row4: [13, 14, null, 15, 16],
        row5: [17, 18, null, 19, 20]
    };

    // Simulated occupied seats
    const occupiedSeats = [3, 7, 12, 16];

    useEffect(() => {
        calculateTotal();
    }, [forms, generalInfo]);

    const calculateTotal = () => {
        let seatTotal = 0;
        let petSum = parseFloat(generalInfo.pet === 'yes' ? generalInfo.petAmount : 0);
        let luggageSum = parseFloat(generalInfo.extraLuggage === 'yes' ? generalInfo.extraLuggageAmount : 0);

        Object.values(forms).forEach(form => {
            seatTotal += parseFloat(form.price) || 0;
        });

        setTotal(seatTotal + petSum + luggageSum);
        setPetTotal(petSum);
        setLuggageTotal(luggageSum);
    };

    const handleSeatClick = (seatNumber) => {
        if (occupiedSeats.includes(seatNumber)) return;

        setSelectedSeats(prev => {
            if (prev.includes(seatNumber)) {
                // Remove seat and its form data
                const newForms = { ...forms };
                delete newForms[seatNumber];
                setForms(newForms);
                return prev.filter(seat => seat !== seatNumber);
            } else {
                // Add seat and initialize form data
                setForms(prev => ({
                    ...prev,
                    [seatNumber]: { name: '', lastName: '', email: '', phone: '', price: '' }
                }));
                return [...prev, seatNumber];
            }
        });
    };

    const handleFormChange = (seatNumber, field, value) => {
        setForms(prev => ({
            ...prev,
            [seatNumber]: {
                ...prev[seatNumber],
                [field]: value
            }
        }));
    };

    const handleGeneralInfoChange = (field, value) => {
        setGeneralInfo(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const resetAll = () => {
        setSelectedSeats([]);
        setForms({});
        setTotal(0);
        setPetTotal(0);
        setLuggageTotal(0);
        setGeneralInfo({
            pickupLocation: '',
            notes: '',
            courtesy: 'no',
            cardPayment: 'no',
            extraLuggage: 'no',
            extraLuggageAmount: '0',
            pet: 'no',
            petAmount: '0',
            transactionType: 'comprar'
        });
    };

    const renderSeat = (number) => {
        if (!number) return <div className="seat-space" />;

        const isOccupied = occupiedSeats.includes(number);
        const isSelected = selectedSeats.includes(number);

        return (
            <button
                key={number}
                onClick={() => handleSeatClick(number)}
                disabled={isOccupied}
                className={`seat ${isOccupied ? 'occupied' : ''} ${isSelected ? 'selected' : ''}`}
                style={{
                    backgroundImage: `url(${isOccupied ? SeatTaken : isSelected ? SeatSelected : SeatAvailable})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                
                {isOccupied ? <span className="font-semibold"></span> :
                    isSelected ? <span className="font-semibold text-white">{number}</span> :
                        <span className="font-semibold">{number}</span>}
            </button>
        );
    };

    return (
        <div className="container-fluid py-4 bg-light">
            <div className="row">
                {/* Bus Layout */}
                <div className="col-md-4 mb-4">
                    <div className="card sticky-top" style={{ top: '1rem' }}>
                        <div className="card-header header-title text-white">
                            <h5 className="card-title mb-0">Leyenda</h5>
                        </div>
                        <div className="card-body">
                            <div className="legend-container p-3 mb-4" style={{ backgroundImage: `url("/placeholder.svg?height=100&width=400")` }}>
                                <div className="d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center">
                                        <div 
                                            className="seat-legend available"
                                            style={{
                                                backgroundImage: `url(${SeatAvailable})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center'
                                            }}
                                        />
                                        <span className="ms-2">Disponible</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div 
                                            className="seat-legend selected"
                                            style={{
                                                backgroundImage: `url(${SeatSelected})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center'
                                            }}
                                        />
                                        <span className="ms-2">Seleccionado</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div 
                                            className="seat-legend occupied" 
                                            style={{
                                                backgroundImage: `url(${SeatTaken})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center'
                                            }}
                                        />
                                        <span className="ms-2">Ocupado</span>
                                    </div>
                                </div>
                            </div>

                            <div className="bus-layout">
                                <div className="driver-seat mb-3">
                                    {/*<svg className="w-100 h-100" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>*/}
                                    <img src={Volante} alt="volante" className="w-12 h-12" />
                                </div>
                                {Object.values(busLayout).map((row, index) => (
                                    <div key={index} className="d-flex justify-content-center gap-2 mb-2">
                                        <div className="d-flex gap-2">
                                            {row.slice(0, 2).map((seat, idx) => (
                                                <React.Fragment key={`left-${idx}`}>
                                                    {renderSeat(seat)}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                        <div className="seat-space" /> {/* Aisle */}
                                        <div className="d-flex gap-2">
                                            {row.slice(3).map((seat, idx) => (
                                                <React.Fragment key={`right-${idx}`}>
                                                    {renderSeat(seat)}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Passenger Forms and General Info */}
                <div className="col-md-8">
                    {/* Sale Summary */}
                    <div className="card mb-4">
                        <div className="card-header header-title text-white">
                            <h5 className="card-title mb-0">Resumen de la venta</h5>
                        </div>
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Concepto</th>
                                        <th className="text-end">Monto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Asientos</td>
                                        <td className="text-end">${total - petTotal - luggageTotal}</td>
                                    </tr>
                                    <tr>
                                        <td>Mascotas</td>
                                        <td className="text-end">${petTotal}</td>
                                    </tr>
                                    <tr>
                                        <td>Equipaje extra</td>
                                        <td className="text-end">${luggageTotal}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr className="fw-bold">
                                        <td>Total</td>
                                        <td className="text-end">${total}</td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <label htmlFor="transactionType" className="form-label me-2 mb-0">
                                        Tipo de transacción:
                                    </label>
                                    <select
                                        id="transactionType"
                                        className="form-select"
                                        value={generalInfo.transactionType}
                                        onChange={(e) => handleGeneralInfoChange('transactionType', e.target.value)}
                                    >
                                        <option value="comprar">Comprar</option>
                                        <option value="reservar">Reservar</option>
                                    </select>
                                </div>
                                <div>
                                    <button
                                        onClick={resetAll}
                                        className="btn btn-danger me-2"
                                    >
                                        Cancelar compra
                                    </button>
                                    <button className="btn btn-success">
                                        Confirmar {generalInfo.transactionType === 'comprar' ? 'compra' : 'reserva'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Passenger Forms */}
                    {selectedSeats.map(seatNumber => (
                        <div key={seatNumber} className="card mb-4">
                            <div className="card-header header-title text-white d-flex justify-content-between align-items-center">
                                <h5 className="card-title mb-0">Asiento {seatNumber}</h5>
                                <button
                                    onClick={() => handleSeatClick(seatNumber)}
                                    className="btn btn-link text-white p-0"
                                >
                                    <X size={20} />
                                </button>
                            </div>
                            <div className="card-body">
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder="Nombres"
                                            value={forms[seatNumber]?.name || ''}
                                            onChange={(e) => handleFormChange(seatNumber, 'name', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder="Apellidos"
                                            value={forms[seatNumber]?.lastName || ''}
                                            onChange={(e) => handleFormChange(seatNumber, 'lastName', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="email"
                                            className="form-control form-control-sm"
                                            placeholder="Email"
                                            value={forms[seatNumber]?.email || ''}
                                            onChange={(e) => handleFormChange(seatNumber, 'email', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <input
                                            type="tel"
                                            className="form-control form-control-sm"
                                            placeholder="Teléfono"
                                            value={forms[seatNumber]?.phone || ''}
                                            onChange={(e) => handleFormChange(seatNumber, 'phone', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <input
                                            type="number"
                                            className="form-control form-control-sm"
                                            placeholder="Precio"
                                            value={forms[seatNumber]?.price || ''}
                                            onChange={(e) => handleFormChange(seatNumber, 'price', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* General Info Card */}
                    {selectedSeats.length > 0 ?  <div className="card mb-4">
                        <div className="card-header header-title text-white">
                            <h5 className="card-title mb-0">Información General</h5>
                        </div>
                        <div className="card-body">
                            <div className="row g-3">
                                <div className="col-md-4">
                                    <label htmlFor="pickupLocation" className="form-label">Donde suben</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="pickupLocation"
                                        value={generalInfo.pickupLocation}
                                        onChange={(e) => handleGeneralInfoChange('pickupLocation', e.target.value)}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="notes" className="form-label">Anotaciones</label>
                                    <textarea
                                        className="form-control form-control-sm"
                                        id="notes"
                                        rows="2"
                                        value={generalInfo.notes}
                                        onChange={(e) => handleGeneralInfoChange('notes', e.target.value)}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label d-block">Cortesía</label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="courtesy"
                                            id="courtesyYes"
                                            value="yes"
                                            checked={generalInfo.courtesy === 'yes'}
                                            onChange={() => handleGeneralInfoChange('courtesy', 'yes')}
                                        />
                                        <label className="form-check-label" htmlFor="courtesyYes">Sí</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="courtesy"
                                            id="courtesyNo"
                                            value="no"
                                            checked={generalInfo.courtesy === 'no'}
                                            onChange={() => handleGeneralInfoChange('courtesy', 'no')}
                                        />
                                        <label className="form-check-label" htmlFor="courtesyNo">No</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label d-block">Pago con tarjeta</label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="cardPayment"
                                            id="cardPaymentYes"
                                            value="yes"
                                            checked={generalInfo.cardPayment === 'yes'}
                                            onChange={() => handleGeneralInfoChange('cardPayment', 'yes')}
                                        />
                                        <label className="form-check-label" htmlFor="cardPaymentYes">Sí</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="cardPayment"
                                            id="cardPaymentNo"
                                            value="no"
                                            checked={generalInfo.cardPayment === 'no'}
                                            onChange={() => handleGeneralInfoChange('cardPayment', 'no')}
                                        />
                                        <label className="form-check-label" htmlFor="cardPaymentNo">No</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label d-block">Exceso de equipaje</label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="extraLuggage"
                                            id="extraLuggageYes"
                                            value="yes"
                                            checked={generalInfo.extraLuggage === 'yes'}
                                            onChange={() => handleGeneralInfoChange('extraLuggage', 'yes')}
                                        />
                                        <label className="form-check-label" htmlFor="extraLuggageYes">Sí</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="extraLuggage"
                                            id="extraLuggageNo"
                                            value="no"
                                            checked={generalInfo.extraLuggage === 'no'}
                                            onChange={() => handleGeneralInfoChange('extraLuggage', 'no')}
                                        />
                                        <label className="form-check-label" htmlFor="extraLuggageNo">No</label>
                                    </div>
                                    {generalInfo.extraLuggage === 'yes' && (
                                        <input
                                            type="number"
                                            className="form-control form-control-sm mt-2"
                                            value={generalInfo.extraLuggageAmount}
                                            onChange={(e) => handleGeneralInfoChange('extraLuggageAmount', e.target.value)}
                                            placeholder="Monto"
                                        />
                                    )}
                                </div>
                                <div className="col-md-4">
                                    <label className="form-label d-block">Mascota</label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="pet"
                                            id="petYes"
                                            value="yes"
                                            checked={generalInfo.pet === 'yes'}
                                            onChange={() => handleGeneralInfoChange('pet', 'yes')}
                                        />
                                        <label className="form-check-label" htmlFor="petYes">Sí</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="pet"
                                            id="petNo"
                                            value="no"
                                            checked={generalInfo.pet === 'no'}
                                            onChange={() => handleGeneralInfoChange('pet', 'no')}
                                        />
                                        <label className="form-check-label" htmlFor="petNo">No</label>
                                    </div>
                                    {generalInfo.pet === 'yes' && (
                                        <input
                                            type="number"
                                            className="form-control form-control-sm mt-2"
                                            value={generalInfo.petAmount}
                                            onChange={(e) => handleGeneralInfoChange('petAmount', e.target.value)}
                                            placeholder="Monto"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div> : null }
                </div>
            </div>
        </div>
    );
};

export default BusBooking;