import React, { useState, useEffect } from "react";
import "./TravelBookingSystem.css";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Loading from "../../components/Loading/Loading";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import SeatSelected from "../../assets/images/seat_selected.svg";
import SeatTaken from "../../assets/images/seat_taken.svg";
import SeatAvailable from "../../assets/images/seat_available.svg";
import Volante from "../../assets/images/volante.png";

export default function TravelBookingSystem() {
  const [loading, setLoading] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 200]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [active1, setActive1] = useState("active");
  const [active2, setActive2] = useState("");
  const [active3, setActive3] = useState("");
  const [active4, setActive4] = useState("");
  const [search, setSearch] = useState([]);
  const [schedule_id, setScheduleId] = useState(0);
  const [branch_id, setBranchId] = useState(0);
  const [branchName, setBranchName] = useState("");
  const [origin_id, setOriginId] = useState(0);
  const [destination_id, setDestinationId] = useState(0);
  const [date, setDate] = useState("");
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [dateSelect, setDateSelect] = useState("");
  const [timeSelect, setTimeSelect] = useState("");
  const [priceSelect, setPriceSelect] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [available, setAvailable] = useState(0);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [occupiedSeats, setOccupiedSeats] = useState([]);
  const [forms, setForms] = useState({});
  const [total, setTotal] = useState(0);
  const [petTotal, setPetTotal] = useState(0);
  const [luggageTotal, setLuggageTotal] = useState(0);
  const [generalInfo, setGeneralInfo] = useState({
    pickupLocation: "",
    notes: "",
    courtesy: 2,
    cardPayment: 2,
    extraLuggage: 2,
    extraLuggageAmount: 0,
    pet: 2,
    petAmount: 0,
    transactionType: 1,
  });
  const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
  const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

  useEffect(() => {
    window.scrollTo(0, 0);
    let dataTrip = JSON.parse(localStorage.getItem("dataTrip"));
    setBranchId(dataTrip.branchId);
    setOriginId(dataTrip.originId);
    setDestinationId(dataTrip.destinationId);
    setDate(dataTrip.dateFormat);
    searchTrips(dataTrip);
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [forms, generalInfo]);

  useEffect(() => {
    setSearch(filterBuses());
  }, [priceRange, selectedTypes]);

  const status_trip = [
    { label: "Comprar", value: 1 },
    { label: "Reservar", value: 2 },
  ];

  const legendItems = [
    { status: "Disponible", colorClass: "bg-success", icon: SeatAvailable },
    { status: "Ocupado", colorClass: "bg-success", icon: SeatTaken },
    { status: "Seleccionado", colorClass: "bg-success", icon: SeatSelected },
  ];

  const busLayout = {
    row1: [0,  0, 0, 14,  1],
    row2: [2,  3, 0,  0,  0],
    row3: [4,  5, 0,  0,  6],
    row4: [7,  8, 0,  0,  9],
    row5: [10,11, 0, 12, 13],
  };

  const handleCloseForm = () => setShowForm(false);
  const handleShowForm = () => setShowForm(true);

  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const searchTrips = async (dataTrip) => {
    if (
      dataTrip.branchId == 0 ||
      dataTrip.originId == 0 ||
      dataTrip.destinationId == 0
    ) {
      Swal.fire({
        icon: "error",
        title: '<div className="title-custom-alert">' + TITLEALERT + "</div>",
        showConfirmButton: true,
        html: '<div className="text-custom-alert">Debe seleccionar las opciones para realizar una busqueda</div>',
        showClass: { popup: "animate__animated animate__fadeInDown" },
        hideClass: { popup: "animate__animated animate__fadeOutUp" },
      });
    } else {
      const form = new FormData();
      form.append("branch_id", dataTrip.branchId);
      form.append("origin_id", dataTrip.originId);
      form.append("destination_id", dataTrip.destinationId);
      form.append("date", dataTrip.dateFormat);
      form.append("type", "web");
      setLoading(true);
      await axios
        .post(ENDPOINT + "search-trip", form)
        .then((response) => {
          setLoading(false);
          setSearch(response.data.data);
          setOrigin(response.data.data[0].origin);
          setDestination(response.data.data[0].destination);
          setDateSelect(response.data.data.date);
        })
        .catch(function (error) {
          setLoading(false);
          Swal.fire({
            icon: "error",
            title:
              '<div className="title-custom-alert">' + TITLEALERT + "</div>",
            showConfirmButton: true,
            timer: 3000,
            html:
              '<div className="text-custom-alert">' +
              error.response.data.msg +
              "</div>",
            showClass: { popup: "animate__animated animate__fadeInDown" },
            hideClass: { popup: "animate__animated animate__fadeOutUp" },
          });
        });
    }
  };

  const busTypes = [
    ...new Set(
      search
        .filter((bus) => bus && bus.name_unit)
        .map((bus) => bus.name_unit.split(" - ")[1])
    ),
  ];

  const filterBuses = () => {
    return search.filter(
      (bus) =>
        bus &&
        bus.price &&
        bus.name_unit &&
        bus.price >= priceRange[0] &&
        bus.price <= priceRange[1] &&
        (selectedTypes.length === 0 ||
          selectedTypes.includes(bus.name_unit.split(" - ")[1]))
    );
  };

  const handlePriceChange = (event) => {
    const value = event.target.value;
    setPriceRange([priceRange[0], value]);
  };

  const handleTypeChange = (type) => {
    setSelectedTypes((prev) =>
      prev.includes(type) ? prev.filter((t) => t !== type) : [...prev, type]
    );
  };

  const sortBuses = (criterio) => {
    setLoading(true);
    setActive1("");
    setActive2("");
    setActive3("");
    setActive4("");
    let sortedBuses = [...search];
    switch (criterio) {
      case "early":
        setActive1("active");
        sortedBuses.sort((a, b) => a.time.localeCompare(b.time));
        break;
      case "late":
        setActive2("active");
        sortedBuses.sort((a, b) => b.time.localeCompare(a.time));
        break;
      case "cheap":
        setActive3("active");
        sortedBuses.sort((a, b) => a.price - b.price);
        break;
      case "expensive":
        setActive4("active");
        sortedBuses.sort((a, b) => b.price - a.price);
        break;
      default:
        break;
    }
    setTimeout(() => {
      setLoading(false);
      setSearch(sortedBuses);
    }, 300);
  };

  const handleChooseTrip = async (
    schedule_id,
    origin,
    destination,
    date,
    time,
    available,
    branchName,
    price
  ) => {
    getAllBookingSeats(schedule_id, date);
    setScheduleId(schedule_id);
    setOrigin(origin);
    setDestination(destination);
    setDateSelect(date);
    setTimeSelect(time);
    setAvailable(available);
    setBranchName(branchName);
    setPriceSelect(price);
    handleShowForm();
  };

  const getAllBookingSeats = async (schedule_id, date) => {
    setLoading(true);
    const form = new FormData();
    form.append("schedule_id", schedule_id);
    form.append("date", date);
    await axios
      .post(ENDPOINT + "get-all-booking-seats", form)
      .then((response) => {
        setLoading(false);
        const seats = response.data.data.map((seat) => seat.seat);
        setOccupiedSeats(seats);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let user_id = 1;
    const form = new FormData();
    const dataForm = JSON.stringify(forms);
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let dateFormat = year + "-" + month + "-" + day;
    form.append("branch_id", branch_id);
    form.append("origin_id", origin_id);
    form.append("destination_id", destination_id);
    form.append("bus_id", schedule_id);
    form.append("user_id", user_id);
    form.append("date", dateFormat);
    form.append("time", timeSelect);
    form.append("data", dataForm);
    form.append("where_up", generalInfo.pickupLocation);
    form.append("annotations", generalInfo.notes);
    form.append("courtesy", generalInfo.courtesy);
    form.append("status", 4);
    form.append("extra_luggage", generalInfo.extraLuggage);
    form.append("pet_extra", generalInfo.pet);
    form.append("price_extra", generalInfo.extraLuggageAmount);
    form.append("price_pet", generalInfo.petAmount);
    form.append("card_payment", generalInfo.cardPayment);
    form.append("type", 2);
    setLoading(true);
    await axios
      .post(ENDPOINT + "save-ticket", form)
      .then((response) => {
        setLoading(false);
        resetAll();
        handlePrint(response.data.data);
        Swal.fire({
          icon: "success",
          title: '<div class="title-custom-alert">' + TITLEALERT + "</div>",
          showConfirmButton: false,
          timer: 3000,
          html:
            '<div class="text-custom-alert">' + response.data.msg + "</div>",
          showClass: { popup: "animate__animated animate__fadeInDown" },
          hideClass: { popup: "animate__animated animate__fadeOutUp" },
        });
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: '<div class="title-custom-alert">' + TITLEALERT + "</div>",
          showConfirmButton: true,
          html:
            '<div class="text-custom-alert">' + error.response.msg + "</div>",
          showClass: { popup: "animate__animated animate__fadeInDown" },
          hideClass: { popup: "animate__animated animate__fadeOutUp" },
        });
      });
  };

  const resetAll = () => {
    setSelectedSeats([]);
    setForms({});
    setTotal(0);
    setPetTotal(0);
    setLuggageTotal(0);
    setGeneralInfo({
      pickupLocation: "",
      notes: "",
      courtesy: 2,
      cardPayment: 2,
      extraLuggage: 2,
      extraLuggageAmount: 0,
      pet: 2,
      petAmount: 0,
      transactionType: 2,
    });
    handleCloseForm();
  };

  const handlePrint = async (id) => {
    setLoading(true);
    let user_id = 1;
    const form = new FormData();
    form.append("id", id);
    form.append("user_id", user_id);
    const response = await axios.post(ENDPOINT + "print-pdf-ticket", form, {
      responseType: "blob",
    });
    const url = window.URL.createObjectURL(response.data);
    window.open(url, "_blank");
    setLoading(false);
    resetAll();
  };

  const handleSeatClick = (seatNumber) => {
    if (occupiedSeats.includes(seatNumber)) return;
    setSelectedSeats((prev) => {
      if (prev.includes(seatNumber)) {
        // Remove seat and its form data
        const newForms = { ...forms };
        delete newForms[seatNumber];
        setForms(newForms);
        return prev.filter((seat) => seat !== seatNumber);
      } else {
        // Add seat and initialize form data
        setForms((prev) => ({
          ...prev,
          [seatNumber]: {
            name: "",
            lastName: "",
            email: "",
            phone: "",
            price: priceSelect,
          },
        }));
        return [...prev, seatNumber];
      }
    });
  };

  const handleFormChange = (seatNumber, field, value) => {
    setForms((prev) => ({
      ...prev,
      [seatNumber]: {
        ...prev[seatNumber],
        [field]: value,
      },
    }));
  };

  const handleGeneralInfoChange = (field, value) => {
    setGeneralInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const calculateTotal = () => {
    let seatTotal = 0;
    let petSum = parseFloat(generalInfo.pet === 1 ? generalInfo.petAmount : 0);
    let luggageSum = parseFloat(
      generalInfo.extraLuggage === 1 ? generalInfo.extraLuggageAmount : 0
    );

    Object.values(forms).forEach((form) => {
      seatTotal += parseFloat(form.price) || 0;
    });

    setTotal(seatTotal + petSum + luggageSum);
    setPetTotal(petSum);
    setLuggageTotal(luggageSum);
  };

  const renderSeat = (number) => {
    if (!number) return <div className="seat-space" />;
    const isOccupied = occupiedSeats.includes(number);
    const isSelected = selectedSeats.includes(number);
    return (
      <button
        key={number}
        onClick={() => handleSeatClick(number)}
        disabled={isOccupied}
        className={`seat ${isOccupied ? "occupied" : ""} ${ isSelected ? "selected" : "" }`}
        style={{
          backgroundImage: `url(${ isOccupied ? SeatTaken : isSelected ? SeatSelected : SeatAvailable })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {isOccupied ? (
          <span className="font-semibold"></span>
        ) : isSelected ? (
          <span className="font-semibold text-white">{number}</span>
        ) : (
          <span className="font-semibold">{number}</span>
        )}
      </button>
    );
  };

  return (
    <>
      {loading && <Loading />}

      <Breadcrumbs
        title="Resultado de busqueda"
        pagename="Resultado de busqueda"
      />

      <Modal
        size="xl"
        show={showForm}
        onHide={handleCloseForm}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="bg-header-modal">
          <Modal.Title>
            {branchName} | {origin} - {destination} | Fecha: {dateSelect} Hora:{" "}
            {timeSelect}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form id="formulario" onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="row">
                {/* Bus Layout */}
                <div className="col-md-4 mb-4">
                  <div className="card sticky-top" style={{ top: "1rem" }}>
                    <div className="card-body">
                      <div className="card bus-legend-card">
                        <div className="card-header text-center">
                          <small className="text-muted">
                            Seleccione sus asientos para continuar con la
                            reserva
                          </small>
                        </div>
                        <div className="card-body">
                          <div className="legend-container">
                            {legendItems.map((item, index) => (
                              <div key={index} className="legend-item">
                                <div
                                  style={{
                                    backgroundImage: `url(${item.icon})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                  }}
                                  className={`seat-icon ${item.colorClass}`}
                                ></div>
                                <span className="legend-text">
                                  {item.status}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="bus-layout">
                        <div className="driver-seat mb-3">
                          <img
                            src={Volante}
                            alt="volante"
                            style={{ width: "32px", height: "32px" }}
                          />
                        </div>
                        {Object.values(busLayout).map((row, index) => (
                          <div key={index} className="d-flex justify-content-center gap-2 mb-2">
                            <div className="d-flex gap-2">
                              {row.slice(0, 2).map((seat, idx) => (
                                <React.Fragment key={`left-${idx}`}>
                                  {renderSeat(seat)}
                                </React.Fragment>
                              ))}
                            </div>
                            <div className="seat-space gap-2" /> {/* Aisle */}
                            <div className="d-flex gap-2">
                              {row.slice(3).map((seat, idx) => (
                                <React.Fragment key={`right-${idx}`}>
                                  {renderSeat(seat)}
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Passenger Forms and General Info */}
                <div className="col-md-8">
                  <div className="passenger-forms-container">
                    {/* Sale Summary */}
                    <div className="card mb-4">
                      <div className="card-header">
                        <h5 className="card-title page-title mb-0">
                          Resumen de la venta
                        </h5>
                      </div>
                      <div className="card-body">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Concepto</th>
                              <th className="text-end">Monto</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Asientos</td>
                              <td className="text-end">
                                ${total - petTotal - luggageTotal}MXN
                              </td>
                            </tr>
                            <tr>
                              <td>Mascotas</td>
                              <td className="text-end">${petTotal}MXN</td>
                            </tr>
                            <tr>
                              <td>Equipaje extra</td>
                              <td className="text-end">${luggageTotal}MXN</td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="fw-bold">
                              <td>Total</td>
                              <td className="text-end">${total}MXN</td>
                            </tr>
                          </tfoot>
                        </table>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <label
                              htmlFor="transactionType"
                              className="form-label me-2 mb-0"
                            >
                              Tipo de transacción:
                            </label>
                            <select
                              id="transactionType"
                              className="form-select form-control form-control-sm"
                              value={generalInfo.transactionType}
                              onChange={(e) =>
                                handleGeneralInfoChange(
                                  "transactionType",
                                  e.target.value
                                )
                              }
                            >
                              {status_trip.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <button
                              type="button"
                              id="closemodal"
                              onClick={resetAll}
                              data-bs-dismiss="modal"
                              className="btn btn-danger btn-sm me-2"
                            >
                              <i className="bi bi-x-square me-2"></i>
                              Cancelar compra
                            </button>
                            <button className="btn btn-success btn-sm">
                              <i className="bi bi-check-square me-2"></i>
                              Confirmar{" "}
                              {generalInfo.transactionType === 1
                                ? "compra"
                                : "reserva"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Passenger Forms */}
                    {selectedSeats.map((seatNumber) => (
                      <div key={seatNumber} className="card mb-4">
                        <div className="card-header header-title text-white d-flex justify-content-between align-items-center">
                          <h5 className="card-title page-title mb-0">
                            Asiento {seatNumber}
                          </h5>
                          <button
                            onClick={() => handleSeatClick(seatNumber)}
                            className="btn close-button text-white p-0"
                          >
                            <i className="bi bi-x-circle-fill"></i>
                          </button>
                        </div>
                        <div className="card-body">
                          <div className="row g-3">
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Nombres"
                                value={forms[seatNumber]?.name || ""}
                                onChange={(e) =>
                                  handleFormChange(
                                    seatNumber,
                                    "name",
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Apellidos"
                                value={forms[seatNumber]?.last_name || ""}
                                onChange={(e) =>
                                  handleFormChange(
                                    seatNumber,
                                    "last_name",
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                required
                                type="email"
                                className="form-control form-control-sm"
                                placeholder="Email"
                                value={forms[seatNumber]?.email || ""}
                                onChange={(e) =>
                                  handleFormChange(
                                    seatNumber,
                                    "email",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <input
                                required
                                type="tel"
                                className="form-control form-control-sm"
                                placeholder="Teléfono"
                                value={forms[seatNumber]?.phone || ""}
                                onChange={(e) =>
                                  handleFormChange(
                                    seatNumber,
                                    "phone",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-2">
                              <input
                                readOnly={true}
                                type="number"
                                className="form-control form-control-sm"
                                placeholder="Precio"
                                value={forms[seatNumber]?.price || priceSelect}
                                onChange={(e) =>
                                  handleFormChange(
                                    seatNumber,
                                    "price",
                                    e.target.value
                                  )
                                }
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}

                    {/* General Info Card */}
                    {selectedSeats.length > 0 ? (
                      <div className="card mb-4">
                        <div className="card-header text-white">
                          <h5 className="card-title page-title mb-0">
                            Información Adicional
                          </h5>
                        </div>
                        <div className="card-body">
                          <div className="row g-3">
                            <div className="col-md-12">
                              <label
                                htmlFor="pickupLocation"
                                className="form-label"
                              >
                                ¿Dondé suben?
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="pickupLocation"
                                value={generalInfo.pickupLocation}
                                placeholder="Ej. En la esquina de la calle"
                                onChange={(e) =>
                                  handleGeneralInfoChange(
                                    "pickupLocation",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-12">
                              <label htmlFor="notes" className="form-label">
                                Anotaciones
                              </label>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="notes"
                                value={generalInfo.notes}
                                placeholder="Ej. Pasajero con discapacidad"
                                onChange={(e) =>
                                  handleGeneralInfoChange(
                                    "notes",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-3 d-none">
                              <label className="form-label d-block">
                                Cortesía
                              </label>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="courtesy"
                                  id="courtesyYes"
                                  value="1"
                                  checked={generalInfo.courtesy === 1}
                                  onChange={() =>
                                    handleGeneralInfoChange("courtesy", 1)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="courtesyYes"
                                >
                                  Sí
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="courtesy"
                                  id="courtesyNo"
                                  value="2"
                                  checked={generalInfo.courtesy === 2}
                                  onChange={() =>
                                    handleGeneralInfoChange("courtesy", 2)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="courtesyNo"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label d-block">
                                Pago con tarjeta
                              </label>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="cardPayment"
                                  id="cardPaymentYes"
                                  value="1"
                                  checked={generalInfo.cardPayment === 1}
                                  onChange={() =>
                                    handleGeneralInfoChange("cardPayment", 1)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardPaymentYes"
                                >
                                  Sí
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="cardPayment"
                                  id="cardPaymentNo"
                                  value="2"
                                  checked={generalInfo.cardPayment === 2}
                                  onChange={() =>
                                    handleGeneralInfoChange("cardPayment", 2)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardPaymentNo"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <label className="form-label d-block">
                                Exceso de equipaje
                              </label>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="extraLuggage"
                                  id="extraLuggageYes"
                                  value="1"
                                  checked={generalInfo.extraLuggage === 1}
                                  onChange={() =>
                                    handleGeneralInfoChange("extraLuggage", 1)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="extraLuggageYes"
                                >
                                  Sí
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="extraLuggage"
                                  id="extraLuggageNo"
                                  value="2"
                                  checked={generalInfo.extraLuggage === 2}
                                  onChange={() =>
                                    handleGeneralInfoChange("extraLuggage", 2)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="extraLuggageNo"
                                >
                                  No
                                </label>
                              </div>
                              {generalInfo.extraLuggage === 1 && (
                                <input
                                  readOnly={true}
                                  type="number"
                                  className="form-control form-control-sm mt-2"
                                  value={generalInfo.extraLuggageAmount}
                                  onChange={(e) =>
                                    handleGeneralInfoChange(
                                      "extraLuggageAmount",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Monto"
                                />
                              )}
                            </div>
                            <div className="col-md-3">
                              <label className="form-label d-block">
                                Mascota
                              </label>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="pet"
                                  id="petYes"
                                  value="1"
                                  checked={generalInfo.pet === 1}
                                  onChange={() =>
                                    handleGeneralInfoChange("pet", 1)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="petYes"
                                >
                                  Sí
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="pet"
                                  id="petNo"
                                  value="2"
                                  checked={generalInfo.pet === 2}
                                  onChange={() =>
                                    handleGeneralInfoChange("pet", 2)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="petNo"
                                >
                                  No
                                </label>
                              </div>
                              {generalInfo.pet === 1 && (
                                <input
                                  readOnly={true}
                                  type="number"
                                  className="form-control form-control-sm mt-2"
                                  value={generalInfo.petAmount}
                                  onChange={(e) =>
                                    handleGeneralInfoChange(
                                      "petAmount",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Monto"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      <div className="container py-5">
        <div className="main-header">
          <div className="title-section">
            <h1 className="main-title">
              <span className="route-title">
                {origin} <i className="bi bi-arrow-right"></i> {destination}
              </span>
              <span className="date-subtitle">
                {date.split("-")[2]} de {months[date.split("-")[1] - 1]} del{" "}
                {date.split("-")[0]}
              </span>
            </h1>
          </div>
          <div className="bus-counter">
            <div className="counter-card">
              <i className="bi bi-bus-front"></i>
              <div className="counter-content">
                <span className="counter-number">{search.length}</span>
                <span className="counter-label">Unidades Disponibles</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-4 filters-column">
            <div className="card shadow filter-section">
              <h5 className="filter-title">Filtros</h5>
              <div className="price-filter">
                <label className="filter-label">
                  Rango de Precios: ${priceRange[0]} - ${priceRange[1]}
                </label>
                <input
                  type="range"
                  min="0"
                  max="200"
                  value={priceRange[1]}
                  onChange={handlePriceChange}
                  className="price-slider"
                />
                <div className="price-range">
                  <span>$0</span>
                  <span>$200</span>
                </div>
              </div>
              <div className="type-filter">
                <h5 className="filter-title">Tipo de Unidad</h5>
                {busTypes.map((type) => (
                  <label key={type} className="type-checkbox">
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      checked={selectedTypes.includes(type)}
                      onChange={() => handleTypeChange(type)}
                    />
                    {type}
                  </label>
                ))}
              </div>
            </div>
            <div className="card shadow sort-section">
              <h5 className="filter-title">Ordenar por</h5>
              <button
                className={`btn btn-outline-primary w-100 mb-2 ${active1}`}
                onClick={() => sortBuses("early")}
              >
                Más Temprano
              </button>
              <button
                className={`btn btn-outline-primary w-100 mb-2 ${active2}`}
                onClick={() => sortBuses("late")}
              >
                Más Tarde
              </button>
              <button
                className={`btn btn-outline-primary w-100 mb-2 ${active3}`}
                onClick={() => sortBuses("cheap")}
              >
                Menor Precio
              </button>
              <button
                className={`btn btn-outline-primary w-100 ${active4}`}
                onClick={() => sortBuses("expensive")}
              >
                Mayor Precio
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-8 buses-column">
            {search
              .filter((bus) => bus && bus.name_unit)
              .map((bus) => (
                <div key={bus.id} className="card shadow mb-4">
                  <div className="card-header">
                    <div className="bus-info">
                      <div className="bus-icon-wrapper">
                        <i
                          className="bi bi-bus-front font-bold"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <h3>{bus.name_unit}</h3>
                    </div>
                    <div className="route-info">
                      <span>{bus.origin}</span>
                      <i className="bi bi-arrow-right"></i>
                      <span>{bus.destination}</span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="journey-info">
                      <div className="location start">
                        <i className="bi bi-geo-alt"></i>
                        <span>{bus.origin}</span>
                      </div>
                      <div className="journey-line">
                        <div className="time-wrapper">
                          <i className="bi bi-alarm"></i>
                        </div>
                        <span style={{ marginTop: "80px" }}>{bus.time}</span>
                      </div>
                      <div className="location end">
                        <i className="bi bi-geo-alt"></i>
                        <span>{bus.destination}</span>
                      </div>
                    </div>
                    <div className="booking-info">
                      <div className="price-availability">
                        <div className="price">
                          ${bus.price}
                          <span>MXN</span>
                        </div>
                        <div className="availability">
                          {bus.available} Disponibles
                        </div>
                      </div>
                      <button
                        onClick={() =>
                          handleChooseTrip(
                            bus.schedule_id,
                            bus.origin,
                            bus.destination,
                            bus.date,
                            bus.time,
                            bus.available,
                            bus.branch_name,
                            bus.price
                          )
                        }
                        className="btn btn-outline-primary"
                      >
                        Seleccionar <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="amenities">
                      <i className="bi bi-wifi" aria-hidden="true"></i>
                      <i className="bi bi-plug" aria-hidden="true"></i>
                      <i className="bi bi-snow" aria-hidden="true"></i>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
