import React from 'react';
import Logo from '../../assets/images/logoviala.png';

const Loading = () => {
    return (
        <>
            <div id="overlay">
                <div className="spinner"></div>
                <span>
                    <div className="d-flex flex-column text-center justify-content-center">
                        <div>
                            <img id="img-pulser" width="80" height="80" src={Logo} />
                            <br /><br /><br />
                            <h2 style={{ fontSize: "24px" }} className="me-3 mb-3"> GRUPO VIALA <i className="bi bi-bus-front me-3"></i></h2>
                        </div>
                        <div style={{ fontSize: "18px" }}>Cargando, por favor espere...</div>
                    </div>
                </span>
            </div>
        </>
    )
}

export default Loading;