import React, { useState, useEffect } from "react";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from '../../components/Loading/Loading';
import { NavLink } from "react-router-dom";

const PaymentSuccess = () => {
    const [loading, setLoading] = useState(false);
    const ENDPOINT = process.env.REACT_APP_URL_ENDPOINT;
    const TITLEALERT = process.env.REACT_APP_TITLE_ALERT;

    useEffect(() => {
        window.scrollTo(0, 0);
        let params = new URLSearchParams(window.location.search);
        let transaction_id = params.get('id');
        paymentStatus(transaction_id);

    }, []);

    const paymentStatus = async (transaction_id) => {
        const form = new FormData();
        form.append('transaction_id', transaction_id);
        setLoading(true);
        const response = await axios.post(ENDPOINT + 'status-payment', form, { responseType: "blob" });
        const url = window.URL.createObjectURL(response.data);
        window.open(url, '_blank');
        setLoading(false);
    };

    return (
        <>
            {loading && <Loading />}
            <Breadcrumbs title="Pago exitoso" pagename="Pago exitoso" />
            <section className="payment pt-5">
                <Container>
                    <Row>
                        <Col md="12">
                            <Card className="m-4 p-4 shadow">
                                <h1 className="mb-2 h3 font-bold text-center">
                                    ¡Excelente! <br />Su pago ha sido confirmado exitosamente.
                                </h1>
                                <p className="text-center">Se ha enviado a tu correo la boleta de viaje.</p>
                                <Row>
                                    <Col className="text-center p-4"> 
                                        <NavLink to="/" className="primaryBtn m-2">
                                            <i className="bi bi-box-arrow-left me-1"></i> Retornar al inicio
                                        </NavLink>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </section>
        </>
    )
}

export default PaymentSuccess;